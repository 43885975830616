<template>
  <section id='author-list'>
    <h1>駐站名人</h1>
    <div class="authors" v-if="!isLoading">
      <div v-for="(author, i) in authors" :key="author.id" class="author">
        <div class="info">
          <router-link :to="author.link">
            <div class='avatar'>
              <img :src="authorAvatar(author.avatar)">
            </div>
            <span class="name has-text-black">{{ author.nickname }}</span>
          </router-link>
          <span class="follow-btn" :class="followClass(author.followed)" @click="follow(author)">
            Follow
            <img :src="followIcon(author.followed)">
          </span>
          <p class="intro">{{ author.introduction }}</p>
          <router-link v-if="author.articles.length > 0" :to="author.link">
            <h4 class="has-text-black">更多文章</h4>
          </router-link>
        </div>
        <ul class="articles">
          <li v-for="(article, i) in author.articles" :key="article.id">
            <router-link :to="article.link" class="title-wrapper">
              <h3 class="has-text-black">{{ article.title }}</h3>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <Modal
      :open="modal"
      :title="'請先登入'"
      :content="'這是會員專屬功能喔！'"
      :btnWord="'好的'"
      @close="closeModal"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Modal from 'src/components/dialog/Modal'

export default {
  data() {
    return {
      isLoading: true,
      modal: false,
    };
  },
  components: { Modal },
  computed: {
    ...mapState(['user', 'authors', 'subscriptions']),
    authorAvatar: function () {
      return avatar => (!!avatar) ? avatar : require('@/../images/editor.jpg')
    },
    followIcon: function () {
      return followed => (followed != true) ? require('@/../images/unfollow.svg') : require('@/../images/follow.svg')
    },
    followClass: function () {
      return followed => (followed != true) ? 'unfollow' : 'follow'
    }
  },
  methods: {
    ...mapActions(['setLoginDrawer']),
    follow(author) {
      if (!!this.user) {
        if (author.followed != true) {
          this.$store.dispatch('follow', { author_id: author.id }).then(() => {
          }).catch((error) => {
            console.log(error)
          })
        } else {
          this.$store.dispatch('unfollow', { author_id: author.id }).then(() => {
          }).catch((error) => {
            console.log(error)
          })
        }
      } else {
        this.modal = true
      }
    },
    closeModal() {
      this.modal = false
      this.setLoginDrawer(true)
    },
  },
  created() {
    this.$store.dispatch('loadAuthors').then(() => {
      this.isLoading = false
    })
  },
};
</script>
<style lang="scss">
  #author-list {
    max-width: 1200px;
    margin: 10px auto;
    h1 {
      margin: 40px auto 0;
      width: 125px;
      font-weight: 500;
      font-size: 26px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 3px;
      position: relative;
      z-index: 1;
      &:after {
        content: '';
        height: 6px;
        background: #FFC2AE;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -10px;
        z-index: -1;
      }
    }
    .authors {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      align-items: flex-start;
      margin-bottom: 100px;
      .author {
        background: #FFF0EE;
        padding: 0 24px 20px;
        margin-top: 75px;
        max-width: 375px;
        .info {
          position: relative;
          font-family: Ubuntu Condensed;
          font-style: normal;
          font-weight: normal;
          text-align: right;
          padding-top: 15px;
          .avatar {
            position: absolute;
            width: 76px;
            border-radius: 50%;
            height: 76px;
            top: -38px;
            left: 50%;
            background: #FFF0EE;
            transform: translateX(-50%);
            img {
              position: relative;
              border-radius: 50%;
              top: 50%;
              transform: translateY(-50%);
              display: block;
              margin: auto;
              width: 60px;
              height: 60px;
            }
          }
          .name {
            position: absolute;
            top: 45px;
            left: 50%;
            transform: translateX(-50%);
            font-family: Ubuntu Condensed;
            font-size: 17px;
            line-height: 10px;
            text-align: center;
            letter-spacing: 1px;
          }
          .follow-btn {
            border-radius: 24px;
            padding: 3px 13px;
            font-size: 15px;
            line-height: 10px;
            letter-spacing: 1px;
            cursor: pointer;
            vertical-align: middle;
            img {
              width: 12px;
            }
          }
          .follow {
            border: #F08F7A solid 1px;
            color: #F08F7A;
          }
          .unfollow {
            border: #989595 solid 1px;
            color: #989595;
          }
          p.intro {
            font-size: 14px;
            line-height: 20px;
            text-align: justify;
            margin: 35px auto 10px;
          }
          h4 {
            width: 70px;
            font-weight: bold;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 1px;
            float: right;
            position: relative;
            z-index: 1;
            &:after {
              content: '';
              height: 4px;
              background: #FFC2AE;
              position: absolute;
              left: 0;
              right: 0;
              bottom: 2px;
              z-index: -1;
            }
          }
        }
        .title-wrapper {
          width: 80vw;
          h3 {
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 19px;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: normal;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
        .articles {
          margin-top: 36px;
          li {
            padding: 8px 5px;
          }
          li + li {
            border-top: 1px solid #FFC2AE;
          }
        }
      }
    }
  }
</style>
