var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"pick-up"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"slick-container"},[_c('b-icon',{staticClass:"slick-arrow prev",attrs:{"icon":"angle-left"},nativeOn:{"click":function($event){return _vm.prev()}}}),_vm._v(" "),(!_vm.isLoading)?_c('slick',{ref:"pickUpSlick",attrs:{"options":_vm.slickOptions}},_vm._l((_vm.pick_up),function(article){return _c('div',{key:article.id,staticClass:"content has-text-centered"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.jumpByBrowser({
            path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
            params: {
              title: article.title
            }
          })}}},[_c('LazyImage',{staticClass:"content-img",attrs:{"src":article.image_url}})],1),_vm._v(" "),_c('div',{staticClass:"slide-text"},[_c('h3',{staticClass:"title has-text-black"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.jumpByBrowser({ name: 'new', query: { category: article.category.path } })}}},[_c('span',{staticClass:"category",class:_vm.cateClass(article.category.id)},[_vm._v(_vm._s(article.category.title))])]),_vm._v(" "),_c('a',{staticClass:"has-text-black",on:{"click":function($event){$event.preventDefault();return _vm.jumpByBrowser({
                path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
                params: {
                  title: article.title
                }
              })}}},[_vm._v("\n              "+_vm._s(article.title)+"\n            ")])])])])}),0):_vm._e(),_vm._v(" "),_c('b-icon',{staticClass:"slick-arrow next",attrs:{"icon":"angle-right"},nativeOn:{"click":function($event){return _vm.next()}}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-block-title has-text-centered has-text-black"},[_c('h2',[_c('span',[_vm._v("P")]),_vm._v("ick Up"),_c('span',{staticClass:"has-text-lookin-darker"},[_vm._v("特別企劃")])])])}]

export { render, staticRenderFns }