<template>
  <div class="outsiderbox">
    <div id="aboutus">
      <div class="container-custom">
        <h1>關於COOLSIS</h1>
        <div class="box">
          <div class="articlebox">
            <!-- <div class="titlebox">
              <span>{{ maintitle }}</span>
            </div> -->
            <div class="contentbox">
              <p>{{ maincontent }}</p>
            </div>
          </div>
          <div class="insidebox contact">
            <div class="titlebox">
              <span>{{ contactinfo.title }}</span>
            </div>
            <div class="contentbox">
              <ul>
                <li v-for="(item, index) in contactinfo.content" :key="index">
                  <div class="title">
                    <span>{{ item.title }}</span>
                  </div>
                  <div>
                    <div class="content" v-html="item.content"></div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="insidebox address">
            <div class="titlebox">
              <span>{{ addressinfo.title }}</span>
            </div>
            <div class="contentbox">
              <ul>
                <li v-for="(item, index) in addressinfo.content" :key="index">
                  <div class="title">
                    <span>{{ item.title }}</span>
                  </div>
                  <div class="content" v-html="item.content"></div>
                </li>
              </ul>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  components: {},
  data() {
    return {
      chooseDate: '2021-12-18T11:06',
      maintitle: '',
      maincontent:
        '酷，從來不是男生的專利！ 女生也能超有態度散發自己的獨特魅力💥 不論是穿搭風格、音樂品味，還是生活態度，酷就是活出自我，掌控自己的節奏💃 你還在煩惱怎麼成為最酷最有個性的潮流女孩嗎？ 別擔心！我們已經幫妳準備好了成為酷女孩的密碼🆒',
      contactinfo: {
        title: '聯絡資訊',
        content: [
          {
            title: '整合行銷部',
            content: '<a href="mailto:daniel@mail2.spp.com.tw">daniel@mail2.spp.com.tw</a>',
          },
          {
            title: '廣告部',
            content: '<a href="mailto:daniel@mail2.spp.com.tw">daniel@mail2.spp.com.tw</a>',
          },
          {
            title: '編輯部',
            content: '<a href="mailto:victoria_chen@mail2.spp.com.tw">victoria_chen@mail2.spp.com.tw</a>',
          },
        ],
      },
      addressinfo: {
        title: 'About LOOKin 尖端媒體集團',
        content: [
          {
            title: '電話：',
            content: '<span>+886-2-2500-7600</span>',
          },
          {
            title: '地址：',
            content:
              "<span class='cht'>104 台北市民生東路2段141號10樓</span><span class='en'>(10F, No. 141, Sec.2 Minsheng E. Rd., Taipei 104, Taiwan)</span>",
          },
        ],
      },
    };
  },
  methods: {},
  created() {},
  mounted() {},
  updated() {},
};
</script>
<style>
* {
  box-sizing: border-box;
}
::selection {
  background: #ffc2ae;
}
html {
  background-color: #fff;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
}
body {
  font-family: Helvetica, Arial, LiHei Pro, 微軟正黑體, Microsoft JhengHei, sans-serif;
}
ul {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
.container-custom {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}
@media (min-width: 576px) {
  .container-custom {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-custom {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-custom {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-custom {
    max-width: 1000px;
  }
}
#aboutus {
  padding: 50px 0 0;
}
#aboutus h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 1px;
  margin: 0 0 30px;
  color: #ffa0b9;
}
#aboutus .box {
  padding: 0;
  box-shadow: none;
}
#aboutus .box .articlebox {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 0 0 15px;
}
#aboutus .box .articlebox .titlebox {
  display: none;
}
#aboutus .box .articlebox .titlebox,
#aboutus .box .articlebox .contentbox {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
#aboutus .box .articlebox .titlebox span,
#aboutus .box .articlebox .contentbox p,
#aboutus .box .insidebox .titlebox span,
#aboutus .box .insidebox .title span,
#aboutus .box .insidebox .contentbox span,
#aboutus .box .insidebox .content span {
  font-size: 16px;
  line-height: 27px;
  color: #000000;
}
#aboutus .box .articlebox .titlebox span {
  font-weight: 600;
  margin: 1.1428em 0 0.5714em;
}
#aboutus .box .articlebox .contentbox p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: justified;
  margin-left: 20px;
  text-indent: 0;
}
#aboutus .box .insidebox {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin: 0 0 15px;
}
#aboutus .box .insidebox .titlebox,
#aboutus .box .insidebox .contentbox {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}
#aboutus .box .insidebox .titlebox {
  margin: 0 0 10px;
}
#aboutus .box .insidebox .titlebox span {
  font-weight: 600;
}
#aboutus .box .insidebox .contentbox ul {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
#aboutus .box .insidebox .contentbox ul li {
  margin: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  /* display: inline-flex;
  flex-wrap: wrap;
  align-items: center; */
  width: 100%;
}
#aboutus .box .insidebox .contentbox ul li .content a {
  color: #ffa0b9;
}
#aboutus .box .insidebox .contentbox ul li .content a:hover {
  color: #363636;
}
#aboutus .box .insidebox .contentbox ul li + li {
  margin-top: 5px;
}
#aboutus .box .insidebox .contentbox ul li .title {
  margin: 0;
  line-height: normal;
  font-size: 0;
  text-align: left;
}
@media (max-width: 767px) {
  #aboutus .box .insidebox .titlebox {
    margin: 0 0 5px;
  }
}
</style>
