<template>
  <section id="desktop-search-overlay" @click="emitCloseDrawer">
    <div id="desktop-search-drawer" class="drawer" @click.stop="handleSearchModal">
      <h2 class="title search-title eng-font">Search</h2>
      <Associate style="width: 640px" :list="associateList" @click="handleAssociate">
        <div class="center input-wrapper">
          <input
            type="text"
            placeholder="Search..."
            class="desktop-search-input"
            v-model="keyword"
            @keydown.enter="submitEvent()"
            @keydown.esc="emitCloseDrawer"
            @input="onInput"
          />
          <div class="icon">
            <img class="del" v-show="showDel" src="../../../images/del.svg" alt="" @click="handleDel" />
            <img @click="submitEvent()" :src="require('@/../images/icon-search.svg')" alt="Search" class="tail-icon" />
          </div>
        </div>
      </Associate>
      <div class="search-history" ref="historyRef" v-if="historyList && historyList.length > 0">
        <TagItem v-for="(tag, index) in historyList" :key="index">
          <span @click="handleTag(tag)">{{ tag }}</span>
        </TagItem>
        <button class="more" @click.stop="isHistoryMore = true">
          <i></i>
          <i></i>
          <i></i>
        </button>
        <HistoryMore
          v-if="isHistoryMore"
          :list="historyTag"
          @click="handleTag"
          @del="handleHistoryTagDel"
          @delAll="delHistoryAll"
        />
      </div>
      <Tag title="Hot Tag" style="margin-top: 34px; margin-bottom: 15px" v-if="search_tags && search_tags.length > 0">
        <TagItem v-for="(word, index) in search_tags" :key="index">
          <span @click="handleTag(word)">#{{ word }}</span>
        </TagItem>
      </Tag>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Tag from '../search/Tag.vue';
import TagItem from '../search/TagItem.vue';
import Associate from '../search/Associate.vue';
import HistoryMore from '../search/HistoryMore.vue';
import { getAssociateList } from '../../../api/search';
import { useReplace } from '../../../tools/useReplace';

export default {
  props: ['open'],
  components: {
    Tag, TagItem, Associate, HistoryMore,
  },
  data() {
    return {
      keyword: '',
      showDel: false,
      associateList: [],
      timeId: null,
      historyTag: [],
      historyList: [],
      historyParams: {
        idx: 5,
        count: 0,
      },
      isHistoryMore: false,
    };
  },
  computed: {
    ...mapState(['search_tags']),
  },
  methods: {
    emitCloseDrawer() {
      this.$emit('closeDrawer', true);
    },
    handleSearchModal() {
      this.isHistoryMore = false;
    },
    submitEvent() {
      if (this.keyword) {
        // this.$message(`seraching... ${this.keyword}`)
        // let keywords = this.keyword.match(/\S+/g).join('-')
        // 去除空格
        const value = this.keyword.split(/[\t\r\f\n\s]*/g).join('');
        // 设置历史记录
        this.storageHistoryFn(value);
        window.location.href = `/nuxt/search?keyword=${value}`;
      }
    },
    handleTag(value) {
      this.$emit('closeDrawer', true);
      window.location.href = `/nuxt/search?keyword=${value}`;
    },
    handleAssociate(value) {
      this.$emit('closeDrawer', true);
      const tag = value.replace(/<\/?[^>]*>/g, '');
      this.storageHistoryFn(tag);
      window.location.href = `/nuxt/search?keyword=${tag}`;
    },
    onInput(event) {
      if (this.timeId) {
        // 如果存在定时器就清空
        clearTimeout(this.timeId);
      }
      // 加个防抖
      this.timeId = setTimeout(async () => {
        const { value } = event.target;
        if (value.trim() !== '') {
          this.showDel = true;

          // 获取搜索联想列表
          try {
            const { code, result } = await getAssociateList(value);
            if (code === 200) {
              // this.associateList = result
              // 高亮
              this.associateList = result.map((item) => useReplace(item, new RegExp(value, 'i'), (val) => `<font color="#f08f7a">${val}</font>`));
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          this.showDel = false;
          this.associateList = [];
        }
      }, 500);
    },
    handleDel() {
      this.keyword = '';
      this.showDel = false;
      this.associateList = [];
    },
    handleHistoryTagDel(tag) {
      this.historyTag = this.historyTag.filter((item) => item !== tag);
      this.historyList = this.historyTag;
      window.localStorage.setItem('history-tag', JSON.stringify(this.historyTag));
    },
    delHistoryAll() {
      this.historyList = [];
      this.historyTag = [];
      window.localStorage.setItem('history-tag', '[]');
    },
    storageHistoryFn(value) {
      const leg = this.historyTag.length;
      // 限制最多显示15个
      if (leg === 15) {
        // 去掉数组最后一个值
        this.historyTag.pop();
        this.historyTag.unshift(value);
        window.localStorage.setItem('history-tag', JSON.stringify(this.historyTag));
      } else {
        // this.historyTag.unshift(tag)
        let arr = [];
        arr = this.historyTag.filter((item) => item !== value);
        arr.unshift(value);
        this.historyTag = arr;
        window.localStorage.setItem('history-tag', JSON.stringify(arr));
      }
    },
  },
  created() {
    const historyTag = window.localStorage.getItem('history-tag');
    this.historyTag = JSON.parse(historyTag || '[]');
    this.historyList = this.historyTag;
  },
  mounted() {
    if (this.$refs.historyRef) {
      const list = this.$refs.historyRef.querySelectorAll('button');
      list.forEach((el, index) => {
        if (el.offsetLeft === 5) {
          this.historyParams.count++;
          if (this.historyParams.count === 2) {
            // 超过一行截取
            this.historyParams.idx = index;
          }
        }
      });

      this.historyList = this.historyTag.slice(0, this.historyParams.idx);
    }
  },
};
</script>
<style lang="scss">
// .drawer {
//   h2.title,
//   h2.title span {
//     font-size: 30px;
//     font-weight: 400;
//     line-height: 24px;
//     letter-spacing: 1px;
//   }
// }

#desktop-search-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background: hsla(0, 0%, 4%, 0);
  display: flex;
  justify-content: center;
  align-items: center;
}

#desktop-search-drawer {
  position: fixed;
  width: 100vw;
  min-height: 460px;
  z-index: 7;
  // overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(220, 220, 220, 1) !important;
  .search-title {
    margin-top: 131px;
  }
  .input-wrapper {
    position: relative;
    // margin-bottom: 60px;
    // input + img {
    //   cursor: pointer;
    // }
    .icon {
      position: absolute;
      right: 25px;
      top: 6px;
      cursor: pointer;
      text-align: right;
      .del {
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }
    }
  }
  .tags {
    margin: 0 10%;
    justify-content: center;
  }
  .search-history {
    width: 640px;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .more {
      height: 26px;
      color: black;
      font-size: 13px;
      background-color: transparent;
      margin: 5px;
      padding: 0 5px;
      border: black solid 1px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      i {
        width: 4px;
        height: 4px;
        margin-right: 4px;
        background-color: black;
        border-radius: 999px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.desktop-search-input {
  // max-width: 640px;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: white;
  padding: 0 67px 0 20px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  &:focus {
    box-shadow: 0px 3px 6px rgba(255, 160, 183, 0.6);
  }
  &::placeholder {
    color: #989595;
  }
  & + .tail-icon {
    position: absolute;
    right: 12px;
    top: 6px;
  }
}

// .tag-link {
//   color: black;
//   font-size: 13px;
//   border-radius: 999px;
//   outline: none;
//   height: 26px;
//   margin: 5px;
//   background-color: transparent;
//   border: black solid 1px;
//   cursor: pointer;
// }
</style>
