<template>
  <div class="home" ref="homeRef">
    <!-- <HomeSlide :isLoading="isLoadingPre" :home_slides="home.slides" type="home" /> -->
    <!-- <Instagram v-if="isMobile" :isLoading="isLoadingPre" :ig_photos="home.ig_photos" /> -->
    <!-- <Advertisement v-if="isMobile" :id="35" :width="300" :height="100" /> -->
    <!-- <HotVideo v-if="home.hot_videos" :isLoading="isLoading" :hot_videos="home.hot_videos" /> -->
    <WhatsNew v-if="home.new_articles" :new_articles="home.new_articles" />
    <!-- <Advertisement v-if="isMobile" :id="36" :width="300" :height="250" /> -->
    <HotIssue v-if="home.hot_issues" :hot_issues="home.hot_issues" />
    <!-- <OnlineMagazine :isLoading="isLoading" :magazines="home.magazines" /> -->
    <PickUp v-if="isMobile" :isLoading="isLoading" :pick_up="home.pick_up" />
    <!-- <MembersOnly :isLoading="isLoading" :member_only="home.member_only" /> -->
    <div :class="isMobile ? '' : 'columns'" id="desktop-container">
      <Recommend v-if="home.recommends" :recommends="home.recommends" />
      <PickUpDesktop v-if="!isMobile" :isLoading="isLoading" :pick_up="home.pick_up" />
    </div>
    <!-- <Instagram v-if="!isMobile" :isLoading="isLoading" :ig_photos="home.ig_photos" /> -->
    <Modal :open="modal" title="Oh No!" :content="thirdPartyMsg" :btnWord="'好的'" @close="close" />
    <!-- <Modal v-if="showLogin"
      :open="modal"
      :title="'✨會員全面升級✨'"
      :content="'為維護會員資安問題，若用 email 登入，請點選［忘記密碼］，回信箱重新驗證升級！小提醒：更新優化期間點數預計於 5/5 前全數匯入！感謝大家'"
      :btnWord="'好的'"
      @close="close"
    /> -->
  </div>
</template>

<script>
import Modal from 'src/components/dialog/Modal';
import Advertisement from 'src/components/common/Advertisement';
import { mapActions, mapState } from 'vuex';
import HomeSlide from './HomeSlide';
import Instagram from './Instagram';
// import HotVideo from './HotVideo'
import WhatsNew from './WhatsNew';
import HotIssue from './HotIssue';
// import OnlineMagazine from './OnlineMagazine'
import PickUp from './PickUp';
import PickUpDesktop from './PickUpDesktop';
// import MembersOnly from './MembersOnly'
import Recommend from './Recommend';
// import { personalizedRecommendation } from '../../../api/article'

export default {
  data() {
    return {
      isLoadingPre: true,
      isLoading: true,
      modal: false,
      thirdPartyMsg: '',
      flag: {
        10: true,
        20: true,
        30: true,
        40: true,
        50: true,
        60: true,
        70: true,
        80: true,
        90: true,
        100: true,
      },
    };
  },
  components: {
    HomeSlide,
    Instagram,
    // HotVideo,
    WhatsNew,
    HotIssue,
    // OnlineMagazine,
    PickUp,
    PickUpDesktop,
    // MembersOnly,
    Recommend,
    Modal,
    Advertisement,
  },
  methods: {
    ...mapActions(['setLoginDrawer']),
    close() {
      // this.setLoginDrawer(true)
      this.modal = false;
    },
    scrollHandler() {
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
      const homeHeight = this.$refs.homeRef.offsetHeight - 800;
      const progress = Math.floor((scrollTop / homeHeight) * 100);
      // 首页页面浏览比率 10%-100%（每10%一个阶段）
      if (progress <= 10) {
        if (!this.flag[10]) return;
        this.flag[10] = false;
        try {
          new TrackEvent().setEvent('read_home', 'read', '10%', null, null);
        } catch (e) {}
      } else if (progress <= 20) {
        if (!this.flag[20]) return;
        this.flag[20] = false;
        try {
          new TrackEvent().setEvent('read_home', 'read', '20%', null, null);
        } catch (e) {}
      } else if (progress <= 30) {
        if (!this.flag[30]) return;
        this.flag[30] = false;
        try {
          new TrackEvent().setEvent('read_home', 'read', '30%', null, null);
        } catch (e) {}
      } else if (progress <= 40) {
        if (!this.flag[40]) return;
        this.flag[40] = false;
        try {
          new TrackEvent().setEvent('read_home', 'read', '40%', null, null);
        } catch (e) {}
      } else if (progress <= 50) {
        if (!this.flag[50]) return;
        this.flag[50] = false;
        try {
          new TrackEvent().setEvent('read_home', 'read', '50%', null, null);
        } catch (e) {}
      } else if (progress <= 60) {
        if (!this.flag[60]) return;
        this.flag[60] = false;
        try {
          new TrackEvent().setEvent('read_home', 'read', '60%', null, null);
        } catch (e) {}
      } else if (progress <= 70) {
        if (!this.flag[70]) return;
        this.flag[70] = false;
        try {
          new TrackEvent().setEvent('read_home', 'read', '70%', null, null);
        } catch (e) {}
      } else if (progress <= 80) {
        if (!this.flag[80]) return;
        this.flag[80] = false;
        try {
          new TrackEvent().setEvent('read_home', 'read', '80%', null, null);
        } catch (e) {}
      } else if (progress <= 90) {
        if (!this.flag[90]) return;
        this.flag[90] = false;
        try {
          new TrackEvent().setEvent('read_home', 'read', '90%', null, null);
        } catch (e) {}
      } else if (progress <= 100) {
        if (!this.flag[100]) return;
        this.flag[100] = false;
        try {
          new TrackEvent().setEvent('read_home', 'read', '100%', null, null);
        } catch (e) {}
      }
    },
  },
  computed: {
    ...mapState(['isMobile', 'home', 'user']),
  },
  created() {},
  mounted() {
    this.$store.dispatch('loadHome').then(() => {
      this.isLoadingPre = false;
      this.$store.dispatch('loadHomeOthers').then(() => {
        this.isLoading = false;
      });
    });

    // 登录第三方注销的账号处理
    const { token, msg } = this.$route.query;
    if (!token && msg) {
      this.thirdPartyMsg = msg;
      this.modal = true;
    }

    window.addEventListener('scroll', this.scrollHandler);
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollHandler);
  },
};
</script>
<style lang="scss">
.home-block-title {
  h2 {
    position: relative;
    display: inline-block;
    margin: 50px auto 40px;
    padding: 0 6px 0 0;
    font-family: 'Ubuntu Condensed', '微軟正黑體', 'Microsoft JhengHei', 'LiHei Pro', Arial, sans-serif;
    font-size: 36px;
    font-weight: 400;
    letter-spacing: 1px;
    line-height: 1;
    span:nth-child(1) {
      width: 45px;
      display: inline-block;
      font-size: 66px;
      text-align: right;
    }
    span.has-text-lookin-darker {
      width: 120px;
      position: absolute;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 2px;
      text-align: left;
      padding: 0;
      transform: translateX(-50%);
      top: 13px;
      left: 106px;
    }
  }
}

span.category.cat-1 {
  background: #bff6ef;
}

span.category.cat-2 {
  background: #fdff9e;
}

span.category.cat-3 {
  background: #ffd6e8;
}

@media screen and (min-width: 1200px) {
  #desktop-container {
    width: 1200px;
    margin: 40px auto;
  }
}
</style>
