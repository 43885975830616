<template>
  <section class="references">
    <p>延伸閱讀</p>
    <div class="article-references">
      <div
        class="article-ref"
        v-for="reference in references"
        :key="reference.id"
        @click="handleArticle(reference.id)"
        ref="extendArticleRef"
        :data-id="reference.id"
      >
        <a
          @click.prevent="jumpByBrowser({
            path: `/${reference.parent_path}/${reference.child_path}/${reference.id}`,
            params: {
              title: reference.title
            }
          })"
        >
          <img :src="reference.image_url || require('@/../images/default.jpg')" />
          <p class="has-text-black">{{ reference.title }}</p>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import useObserver from '../../../tools/useObserver.js';

export default {
  props: ['references', 'articleId'],
  data() {
    return {};
  },
  mounted() {
    const extendRefs = this.$refs.extendArticleRef;
    if (Array.isArray(extendRefs)) {
      extendRefs.map((el) => {
        useObserver(el, () => {
          try {
            const { id } = el.dataset;
            new TrackEvent().setEvent('impression_article', 'extend', this.articleId, id, null);
          } catch (error) {
            console.log(error);
          }
        });
      });
    }
  },
  methods: {
    handleArticle(id) {
      try {
        new TrackEvent().setEvent('article_extend', 'click', this.articleId, id, null);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.references {
  max-width: 810px;
  margin: 15px auto;
  & > p {
    margin: 40px 20px 10px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 1px;
    text-align: left;
  }
}
.article-references {
  display: flex;
  flex-direction: column;
  .article-ref {
    margin: 10px 4%;
    img {
      width: 40%;
      border: 1px solid #ffa0b9;
      box-sizing: border-box;
      vertical-align: top;
    }
    p {
      width: 55%;
      display: inline-block;
      margin-left: 3%;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
    }
  }
}

@media screen and (min-width: 1200px) {
  .article-references {
    flex-direction: row;
    .article-ref {
      margin: 10px 3%;
      img {
        width: 220px;
      }
      p {
        width: 220px;
        margin: 12px 0 10px;
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
</style>
