<template>
  <footer id="footer" class="footer has-background-black">
    <div class="footer-container">
      <div v-if="!isMobile" class="desktop-logos">
        <a @click.prevent="jumpByBrowser({ name: 'home' })" class="footer-logo">
          <img :src="require('@/../images/logo_footer.png')" alt="COOL SIS 酷女孩">
        </a>
        <div class="social-links">
          <!-- <a href="https://www.youtube.com/channel/UC9FymR5DhAubxbjWM9JmS_Q?sub_confirmation=1" target="_blank">
            <img :src="require('@/../images/youtube.svg')" alt="YouTube">
          </a> -->
          <a href="https://www.facebook.com/coolsistw?mibextid=kFxxJD&mibextid=kFxxJD" target="_blank">
            <img :src="require('@/../images/facebook.svg')" alt="Facebook">
          </a>
          <a href="https://www.instagram.com/coolsis_taiwan/" target="_blank">
            <img :src="require('@/../images/instagram.svg')" alt="Instagram">
          </a>
          <!-- <a href="https://line.me/R/ti/p/@tci1308w" target="_blank">
            <img :src="require('@/../images/line.svg')" alt="LINE">
          </a> -->
        </div>
      </div>
      <div class="flex-container">
        <div class="links">
          <!-- <router-link :to="{ name: 'sitemap' }" class="footer-link has-text-white">網站總覽</router-link> -->
          <a class="footer-link has-text-white" href='mailto:daniel@mail2.spp.com.tw' target="_blank">廣告刊登</a>
          <!-- <a class="footer-link has-text-white" href='mailto:ilovelookin520@gmail.com' target="_blank">聯絡我們</a> -->
          <router-link :to="{ name: 'aboutus' }" class="footer-link has-text-white">關於我們</router-link>
          <router-link :to="{ name: 'privacy' }" class="footer-link has-text-white">隱私權政策</router-link>
        </div>
        <div class="copy-right has-text-white">
          <p>尖端媒體集團 版權所有，禁止未經授權轉貼節錄</p>
          <p>©1999 - 2025 Sharp Point Media Group, a division of Cite Publishing Ltd. All rights reserved.</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['isMobile']),
  },
};
</script>

<style lang="scss">
#footer {
  padding: 20px 15px;
  .footer-container {
    margin: 0 auto;
    .flex-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .links {
        padding: 2px;
        text-align: center;
        .footer-link {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          text-align: center;
          padding: 1px 6px;
        }
        .footer-link:not(:first-child) {
          border-left: 1px solid #FFFFFF;
        }
      }
      .copy-right {
        font-size: 12px;
        line-height: 140%;
        letter-spacing: 1px;
        text-align: center;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #footer {
    padding: 30px;
    .footer-container {
      max-width: 1100px;
      .desktop-logos {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        margin-bottom: 22px;
        .footer-logo img {
          width: 165px;
        }
        .social-links {
          margin: 8px 14px;
          a {
            border: 1px solid #FFFFFF;
            border-radius: 50%;
            box-sizing: border-box;
            margin: 0 12px;
            padding: 7px;
            display: inline-block;
            height: 40px;
            width: 40px;
            img {
              -webkit-filter: invert(100%);
                      filter: invert(100%);
              width: 25px;
            }
          }
        }
      }
      .flex-container {
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;
        .copy-right {
          text-align: left;
        }
      }
    }
  }
}
</style>
