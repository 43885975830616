<template>
  <section class="article-container">
    <div class="article">
      <div class="article-header">
        <BreadCrumb :breadcrumbList="breadcrumb(article)" />
        <h1 class="title">
          <img
            v-if="article && article.member_only"
            :src="require('@/../images/vip.svg')"
            class="vip-icon"
          />
          <a
            @click.prevent="jumpByBrowser({
              name: 'sub_channel',
              params: {
                channel: article.parent_path,
                sub_channel: article.child_path,
              },
              query: { category: article.category.path },
            })"
          >
            <span
              v-if="article.category"
              class="category has-text-black"
              :class="cateClass(article.category.id)"
            >
              {{ article.category.title }}
            </span>
          </a>
          <a
            @click.prevent="jumpByBrowser({
              name: 'article',
              params: {
                channel: article.parent_path,
                sub_channel: article.child_path,
                id: article.id,
                title: article.title,
              },
            })"
            class="has-text-black"
          >
            {{ article.title }}
          </a>
        </h1>
        <p class="author">
          <a @click.prevent="jumpByBrowser({ path: article.author_url })">
            <img class="avatar" :src="authorAvatar(article.author_avatar)" />
            <span class="name has-text-black">{{ article.author_name }}</span>
          </a>
          <!-- <span class="follow" @click="follow(article)">Follow</span>
          <img
            :src="followIcon(article.followed)"
            class="heart"
            @click="follow(article)"
          /> -->
        </p>
        <div class="social-share">
          <span class="date-text">最後更新日</span>
          <span>{{ article.published_at }}</span>
          <div class="social-icons">
            <div
              v-if="isMobile"
              class="has-background-nav-bg"
              @click="share(article)"
            >
              <img :src="require('@/../images/share.svg')" />
            </div>
            <div
              v-if="!isMobile"
              class="has-background-nav-bg"
              @click="fbShare()"
            >
              <img :src="require('@/../images/facebook.svg')" />
            </div>
            <div
              v-if="!isMobile"
              class="has-background-nav-bg"
              @click.stop="handleLineShare"
            >
              <!-- <a :href="lineShareURL()" @click.stop="handleLineShare"> -->
              <img :src="require('@/../images/line.svg')" />
              <!-- </a> -->
            </div>
            <!-- <div
              @click="bookmark(article)"
              :class="bookmarkClass(article.bookmarked)"
            >
              <img :src="require('@/../images/keep.svg')" />
            </div> -->
            <div
              v-scroll-to="{ element: `#articles-${article.id}`, duration: 0 }"
              @click="resize"
              class="has-background-nav-bg"
            >
              <img :src="resizeIcon" />
            </div>
          </div>
        </div>
        <slick
          ref="articleSlick"
          :options="slickOptions"
          v-if="article"
          @afterChange="handleAfterChange()"
        >
          <div
            class="content has-text-centered"
            v-for="(slide, i) in article.figures"
            :key="i"
          >
            <img
              width="100vw"
              :srcset="`${slide.image_s_url} 800w, ${slide.image_url} 1024w`"
              :src="slide.image_s_url || require('@/../images/default.jpg')"
            />
          </div>
        </slick>
      </div>
      <div class="article-content">
        <div ref="content" class="fr-view" v-html="article.content"></div>
      </div>
      <JoinEvent v-if="prizeInfo" :articleId="article.id" :info="prizeInfo" />
      <div v-if="!isAuthed && article.member_only" class="member-only-wrapper">
        <div class="member-only-cover">
          <b-button type="is-nav-bg" rounded @click="loginRequired('member')"
            >立即登入會員，查看完整文章！</b-button
          >
        </div>
      </div>
      <div v-else>
        <div class="article-tags">
          <a
            v-for="tag in article.tags"
            :key="tag"
            class="tag-link"
            @click.prevent="jumpByBrowser({
              name: 'tag',
              params: { slug: tag.toLowerCase(), title: tag },
            })"
            >#{{ tag }}</a
          >
        </div>
        <!-- <RecommendArticle v-if="isMobile" :articleId="article.id" /> -->
        <References :references="article.references" :articleId="article.id" />
        <!-- <Comments
          :article_id="article.id"
          :comments="article.comments"
          :count="article.comments_count"
          :key="commentsKey"
        /> -->
      </div>
    </div>
    <RightSideBar v-if="!isMobile && !recommend" :articleId="article.id" />
  </section>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { categoryClass, resizeFont, fullTitle } from 'src/share';
import Comments from 'src/components/common/Comments';
import BreadCrumb from 'src/components/common/BreadCrumb';
import RightSideBar from 'src/components/layout/RightSideBar';
import JoinEvent from './JoinEventNew';
import References from './References';
import { Android, shareAndroid, isLookinApp } from '../../../tools/bridge';
// import RecommendArticle from '../../components/layout/RecommendArticle.vue'
import { getArticlePrizeInfo } from '../../../api/exchange';

export default {
  name: 'Article',
  props: ['article', 'recommend'],
  data() {
    return {
      isGB: false, // 是否是广编
      slickOptions: {
        autoplay: true,
        autoplaySpeed: 8000,
        infinite: true,
        slidesToShow: 1,
        dots: true,
        arrows: false,
        swipeToSlide: true,
      },
      wordSize: 'medium',
      fontList: ['small', 'big'],
      normalized: false,
      tagNumber: 0,
      commentsKey: 0,
      prizeInfo: null, // 贈品信息
    };
  },
  components: {
    References, JoinEvent, BreadCrumb, Comments, RightSideBar,
  },
  computed: {
    ...mapState(['token', 'isMobile', 'channels', 'user', 'inter_video']),
    ...mapGetters(['isAuthed']),
    authorAvatar() {
      return (avatar) => (avatar || require('@/../images/editor.jpg'));
    },
    bookmarkClass() {
      return (bookmarked) => (bookmarked == true
        ? 'has-background-lookin-darker'
        : 'has-background-nav-bg');
    },
    followIcon() {
      return (followed) => (followed == true
        ? require('@/../images/follow.svg')
        : require('@/../images/unfollow.svg'));
    },
    canLoadMore() {
      return (
        this.$route.name != 'preview'
        && (this.isAuthed || !(this.article && this.article.member_only))
      );
    },
    resizeIcon() {
      return this.wordSize == 'big'
        ? require('@/../images/font.svg')
        : require('@/../images/font_plus.svg');
    },
  },
  created() {
    // this.articlePrizeInfoFn(this.article.id);
    this.addLinkClickFunc();
    // 插入锚点所需样式
    // this.insertStyle()
    setTimeout(() => {
      // 目录添加边框样式
      this.wrapAnchorsInDiv();
      // 目录添加按钮
      this.addCollapseButton();
      this.insertMenu();
    }, 300);
  },
  mounted() {
    // 解决ios a标签不能跳转问题
    const links = document.querySelectorAll("a[target='_blank']");
    if (links && isLookinApp) {
      links.forEach((el) => {
        el.target = '_self';
      });
    }
    this.$refs.content.addEventListener('click', this.anchorClickMove);
  },
  beforeDestroy() {
    this.$refs.content.removeEventListener('click', this.anchorClickMove);
  },
  methods: {
    ...mapActions(['setLoginDrawer', 'setVia']),
    cateClass: (id) => categoryClass(id),

    // 点击锚点 平滑移动
    anchorClickMove(event) {
      let { target } = event;
      // 循环检查目标及其父元素，直到找到锚点链接
      while (target && target !== document) {
        if (
          target.tagName === 'A'
          && target.getAttribute('href').startsWith('#')
        ) {
          event.preventDefault();
          const targetId = target.getAttribute('href').substring(1);
          const targetElement = document.getElementById(targetId);
          if (targetElement) {
            window.scrollTo({
              top: targetElement.offsetTop + 150, // 调整后的偏移量
              behavior: 'smooth', // 平滑滚动效果
            });
          }
          return;
        }
        target = target.parentElement; // 向上检查父元素
      }
    },

    // 检查当前元素的所有子元素是否包含锚点目录
    containsAnchorLink(element) {
      // 获取当前元素及其子元素中的所有 a 标签
      const aElements = element.querySelectorAll('a');
      // 检查是否存在 href 以 # 开头的 a 标签
      return Array.prototype.some.call(aElements, (a) => a.getAttribute('href') && a.getAttribute('href').startsWith('#'));
    },

    // 对锚点目录添加样式
    wrapAnchorsInDiv() {
      let isDone = false;
      // 找到所有包含 <h3> 标签的 <ol> 元素 ol包含数字列表
      const olElements = document.querySelectorAll('ol');
      if (olElements && olElements.length > 0) {
        console.log('处理ol===', olElements);
        olElements.forEach((ol) => {
          // 检查当前 <ol> 是否包含符合条件的 <h3> 标签
          const hasAnchorLink = Array.from(ol.querySelectorAll('h3 a')).some(
            (a) => a.getAttribute('href').startsWith('#'),
          );
          const hasAnchorLinkH4 = Array.from(ol.querySelectorAll('h4 a')).some(
            (a) => a.getAttribute('href').startsWith('#'),
          );
          if (hasAnchorLink || hasAnchorLinkH4) {
            // 确保是最外层的 <ol>，即父元素不是 <li>
            if (ol.parentElement.tagName !== 'LI') {
              // 给最外层 <ol> 添加 class
              ol.classList.add('anchor-wrapper');
              isDone = true;
            }
          }
        });
      }
      if (isDone) return;
      // 处理 ul 包裹的锚点目录元素
      const ulElements = document.querySelectorAll('ul');
      if (ulElements && ulElements.length > 0) {
        console.log('处理ul===');
        ulElements.forEach((ul) => {
          // 检查当前 ul 是否包含符合条件的 a 标签
          if (this.containsAnchorLink(ul)) {
            // 查找最外层的 ul 元素
            let outerUl = ul;
            while (outerUl.parentElement.closest('ul')) {
              outerUl = outerUl.parentElement.closest('ul');
            }
            // 只给最外层的 ul 添加 class
            outerUl.classList.add('anchor-wrapper');
            isDone = true;
          }
        });
      }
      if (isDone) return;
      // 没有ul ol等包裹的但是又符合锚点目录特征的 在外层添加一个div包裹
      let wrapperDiv = null;
      // 处理h3包裹的锚点目录
      const h3Elements = document.querySelectorAll('h3');
      if (h3Elements && h3Elements.length > 0) {
        console.log('处理h3===');
        h3Elements.forEach((h3) => {
          const anchor = h3.querySelector('a');
          if (anchor && anchor.getAttribute('href').startsWith('#')) {
            if (!wrapperDiv) {
              wrapperDiv = document.createElement('div');
              wrapperDiv.classList.add('anchor-wrapper');
              h3.parentNode.insertBefore(wrapperDiv, h3);
            }
            wrapperDiv.appendChild(h3);
            isDone = true;
          } else {
            wrapperDiv = null;
          }
        });
      }
      if (isDone) return;
      // 处理h4包裹的锚点目录
      const h4Elements = document.querySelectorAll('h4');
      if (h4Elements && h4Elements.length > 0) {
        console.log('处理h4===');
        h4Elements.forEach((h4) => {
          const anchor = h4.querySelector('a');
          if (anchor && anchor.getAttribute('href').startsWith('#')) {
            if (!wrapperDiv) {
              wrapperDiv = document.createElement('div');
              wrapperDiv.classList.add('anchor-wrapper');
              h4.parentNode?.insertBefore(wrapperDiv, h4);
            }
            wrapperDiv.appendChild(h4);
          } else {
            wrapperDiv = null;
          }
        });
      }
    },

    // 目录折叠按钮事件
    toggleCollapse(event) {
      const button = event.target;
      const wrapperDiv = button.parentElement;
      const isCollapsed = wrapperDiv.classList.toggle('collapsed');
      if (isCollapsed) {
        button.classList.add('up');
        // 创建并插入目录 div
        // const menuDiv = document.createElement('div')
        // menuDiv.textContent = '目錄'
        // menuDiv.classList.add('menu-text')
        // wrapperDiv.insertBefore(menuDiv, wrapperDiv.firstChild.nextSibling)
      } else {
        button.classList.remove('up');
        // 移除目录 div
        // const menuDiv = wrapperDiv.querySelector('.menu-text')
        // if (menuDiv) {
        //   wrapperDiv.removeChild(menuDiv)
        // }
      }
      // 根据折叠状态设置 max-height
      if (isCollapsed) {
        wrapperDiv.style.maxHeight = '40px'; // 这里可以根据实际情况调整高度
      } else {
        wrapperDiv.style.maxHeight = 'none'; // 恢复原始高度
      }
    },

    insertMenu() {
      const anchorWrapperElement = document.querySelector('.anchor-wrapper');
      const menuDiv = document.createElement('div');
      menuDiv.textContent = '目錄';
      menuDiv.classList.add('menu-text');
      anchorWrapperElement.insertBefore(
        menuDiv,
        anchorWrapperElement.firstChild.nextSibling,
      );
    },

    // 添加折叠按钮
    addCollapseButton() {
      const anchorWrapperElement = document.querySelector('.anchor-wrapper');
      if (anchorWrapperElement) {
        // 如果折叠按钮还未创建，则创建并插入到 anchor-wrapper 元素内部
        if (!anchorWrapperElement.querySelector('.collapse-button')) {
          // 添加折叠按钮
          const collapseButton = document.createElement('div');
          collapseButton.classList.add('collapse-button');
          collapseButton.addEventListener(
            'click',
            this.toggleCollapse.bind(this),
          );
          anchorWrapperElement.insertBefore(
            collapseButton,
            anchorWrapperElement.firstChild,
          );
        }
      }
    },

    // 给a标签添加额外的属性
    addOnClickToLinks(htmlString, titleString) {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlString, 'text/html');
      const aTags = doc.getElementsByTagName('a');
      for (let i = 0; i < aTags.length; i++) {
        // 是否是锚点
        const isAnchor = aTags[i].getAttribute('anchor')
          || aTags[i].getAttribute('href').charAt(0) === '#';
        // 非锚点的a标签才加指定 title属性
        if (!isAnchor) {
          aTags[i].setAttribute('title', titleString);
          // aTags[i].setAttribute('onclick', 'trackOuterLink(event, this)')
        }
      }
      // 将修改后的文档对象的 HTML 内容转换回字符串并返回
      return doc.body.innerHTML;
    },

    // 自定义点击事件
    trackOuterLink(event, el) {
      // event.preventDefault()
      const { href } = el; // 获取 href 属性
      const text = el.textContent; // 获取文案（文本内容）
      console.log('点击外链:', href, text);
      try {
        new TrackEvent().setEvent('gb_link_click', 'click', text, href, '');
      } catch (err) {
        console.warn('广编外链点击上报失败', err);
      }
    },

    // 如果是广编，额外添加外链的点击事件
    addLinkClickFunc() {
      // 原則上是有 #LOOKin編輯推薦 這個標籤的大致都是广编
      this.isGB = this.article.tags.includes('LOOKin編輯推薦');
      if (this.isGB) {
        // window.trackOuterLink = this.trackOuterLink
        // richText = richText.replace(/<p>(目录\d+)\s*`(#\w+)`<\/p>/g, '<p><h4><a href="$2">$1</a></h4></p>');

        const html = this.addOnClickToLinks(
          this.article.content,
          'gb_link_click',
        );
        this.article.content = html;
        // console.log('添加属性后:', this.article.content)
      }
    },

    handleAfterChange() {
      this.$gtag.pageview({
        page_path: this.article.link,
        page_title: fullTitle(this.article.title),
        page_location: window.location.href,
        send_to: process.env.GA_TRACKING_ID,
      });
      // 文章轮播添加统计代码
      try {
        new TrackEvent().setEvent(
          'article_slide',
          'handleAfterChange',
          '',
          null,
          null,
        );
      } catch (e) {
        console.log(e);
      }
    },
    breadcrumb(article) {
      return [
        {
          title: '首頁',
          name: 'home',
        },
        {
          title: this.channelName(article.parent_path),
          name: 'channel',
          parent: article.parent_path,
        },
        {
          title: this.channelName(article.child_path),
          name: 'sub_channel',
          parent: article.parent_path,
          child: article.child_path,
        },
      ].filter((item) => !!item.title);
    },
    resize() {
      let delta = 3;
      switch (this.wordSize) {
        case 'medium':
          this.wordSize = 'big';
          break;
        case 'big':
          this.wordSize = 'small';
          delta = -5;
          break;
        case 'small':
          this.wordSize = 'medium';
          delta = 2;
          break;
      }
      const content = document.getElementsByClassName('article-content');
      for (let i = 0; i < content.length; i++) {
        resizeFont(content[i], delta);
        for (let j = 0; j < this.fontList.length; j++) {
          if (this.fontList[j] == this.wordSize) {
            if (!content[i].classList.contains(this.wordSize)) {
              content[i].classList.add(this.wordSize);
            }
          } else if (content[i].classList.contains(this.fontList[j])) {
            content[i].classList.remove(this.fontList[j]);
          }
        }
      }
    },
    theSameSize() {
      const content = document.getElementsByClassName('article-content');
      switch (this.wordSize) {
        case 'big':
          for (let i = 1; i < content.length; i++) {
            resizeFont(content[i], 3);
            for (let j = 0; j < this.fontList.length; j++) {
              if (this.fontList[j] == this.wordSize) {
                if (!content[i].classList.contains(this.wordSize)) {
                  content[i].classList.add(this.wordSize);
                }
              } else if (content[i].classList.contains(this.fontList[j])) {
                content[i].classList.remove(this.fontList[j]);
              }
            }
          }
          break;
        case 'small':
          for (let i = 1; i < content.length; i++) {
            resizeFont(content[i], -2);
            for (let j = 0; j < this.fontList.length; j++) {
              if (this.fontList[j] == this.wordSize) {
                if (!content[i].classList.contains(this.wordSize)) {
                  content[i].classList.add(this.wordSize);
                }
              } else if (content[i].classList.contains(this.fontList[j])) {
                content[i].classList.remove(this.fontList[j]);
              }
            }
          }
          break;
      }
      this.normalized = true;
    },
    share(article) {
      const shareObj = {
        title: article.title,
        text: article.title,
        url: `${
          window.location.href
        }?utm_medium=website&utm_campaign=${this.campaign()}`,
      };

      if (navigator.share && navigator.canShare) {
        navigator
          .share(shareObj)
          .then()
          .catch((error) => console.log('Error sharing:', error));
      } else if (Android) {
        shareAndroid(shareObj);
      }
    },
    fbShare() {
      // 调用统计代码
      // 引入js函数方法
      try {
        new TrackEvent().setEvent('fb_share_click', '', '', null, null);
      } catch (e) {
        console.log(e);
      }

      FB.ui(
        {
          method: 'share',
          href: `${
            window.location.href
          }%3Futm_source=fb%26utm_medium=website%26utm_campaign=${this.campaign()}`,
        },
        (response) => {
          // 调用统计代码
          // 引入js函数方法
          try {
            new TrackEvent().setEvent('fb_share_callback', '', '', null, null);
          } catch (e) {
            console.log(e);
          }
        },
      );
    },
    handleLineShare() {
      // 调用统计代码
      try {
        new TrackEvent().setEvent('line_share_click', '', '', null, null);
      } catch (e) {
        console.log(e);
      }
      window.location.href = this.lineShareURL();
    },
    lineShareURL() {
      return `https://social-plugins.line.me/lineit/share?url=${
        window.location.href
      }%3Futm_source=Line%26utm_medium=website%26utm_campaign=${this.campaign()}`;
    },
    campaign() {
      return this.user ? this.user.id : 'share';
    },
    channelName(slug) {
      if (this.channels) {
        let channel = this.channels.find((ch) => ch.slug == slug);
        if (channel === undefined) {
          for (let i = 0; i < this.channels.length; i++) {
            if (!this.channels[i].channels) continue;
            channel = this.channels[i].channels.find((ch) => ch.slug == slug);
            if (channel !== undefined) {
              break;
            }
          }
        }
        if (channel !== undefined) {
          return channel.title;
        }
      }
    },
    loginRequired(path) {
      if (this.user) {
        // skip
      } else {
        this.setVia({
          type: 'Article',
          id: this.article.id,
          path,
        });
        if (path == 'member') {
          this.setLoginDrawer(true);
        } else {
          this.$emit('showModal');
        }
      }
    },
    bookmark(article) {
      if (this.user) {
        if (article.bookmarked == true) {
          this.$store
            .dispatch('removeBookmark', article.id)
            .then(() => {})
            .catch((error) => {
              console.log(error.data);
            });
        } else {
          this.$store
            .dispatch('addBookmark', article.id)
            .then(() => {})
            .catch((error) => {
              console.log(error.data);
            });
        }
      } else {
        this.loginRequired('bookmark');
      }
    },
    follow(article) {
      if (this.user) {
        if (article.followed == true) {
          this.$store
            .dispatch('unfollow', {
              author_id: article.author_id,
              article_id: article.id,
            })
            .then(() => {})
            .catch((error) => {
              console.log(error.data);
            });
        } else {
          this.$store
            .dispatch('follow', {
              author_id: article.author_id,
              article_id: article.id,
            })
            .then(() => {})
            .catch((error) => {
              console.log(error.data);
            });
        }
      } else {
        this.loginRequired('follow');
      }
    },
    async articlePrizeInfoFn(id) {
      try {
        const { code, result } = await getArticlePrizeInfo(id);
        if (code === 200) {
          this.prizeInfo = result;
        }
      } catch (error) {}
    },
  },
};
</script>
<style lang="scss">
.extended {
  font-size: 18px;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ol li,
ul li {
  margin-left: 20px; /* 每级子列表缩进 */
  position: relative; /* 为自定义样式定位 */
  list-style-position: outside;
}

ul li::before {
  content: '';
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: transparent;
  border-radius: 50%;
}
.anchor-wrapper {
  position: relative;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  overflow: hidden;
  transition: all 0.3s ease;
  max-height: 2000px; /* 初始值，足够大以确保内容展开 */
  list-style-type: decimal; /* 确保嵌套的 <ol> 也使用数字样式 */
}
ul li::marker {
  color: #f08f7a;
  font-weight: bold;
}
ol li::marker {
  color: #f08f7a;
  font-weight: bold;
}

/*.anchor-wrapper::after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-left: 1px solid rgba(0,0,0,0.8);
          border-top: 1px solid rgba(0,0,0,0.8);
          top: 10px;
          right: 13px;
          transform: rotate(45deg);
          z-index: 1;
        }*/
/* 折叠按钮样式
        .collapse-button {
            position: absolute;
            top: 10px;
            right: 10px;
            background: none;
            color:rgba(0,0,0,0.5);
            border: none;
            cursor: pointer;
            z-index:10;
            font-size: 16px;
        }*/
.collapse-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 10px;
  height: 10px;
  border-left: 1px solid rgba(0, 0, 0, 0.8);
  border-top: 1px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s ease;
  transform: rotate(45deg);
  cursor: pointer;
  z-index: 10;
  font-size: 16px;
}
.menu-text {
  color: rgba(0, 0, 0, 0.8);
  padding: 6px 0 6px 0;
}
/* 折叠状态样式 */
.anchor-wrapper.collapsed {
  max-height: 40px; /* 折叠时将最大高度设置为0 */
  max-width: 100px;
  padding-top: 0;
  padding-bottom: 0;
}
.anchor-wrapper.collapsed a {
  visibility: hidden;
}

.up {
  transform: rotate(225deg);
}
@media screen and (min-width: 810px) {
  .article-header {
    h1.title {
      margin: 36px 0 26px !important;
    }
  }
}

.article-container {
  position: relative;
}

.article {
  margin-top: 50px;
}

.article-header {
  margin: 15px auto;
  max-width: 810px;
  font-size: 16px;
  font-weight: 400;
  line-height: 10px;
  color: #000000;
  .vip-icon {
    width: 21px;
  }
  .author {
    font-family: 'Ubuntu Condensed', '微軟正黑體', 'Microsoft JhengHei',
      'LiHei Pro', Arial, sans-serif;
    font-size: 16px;
    .avatar {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      margin-right: 8px;
      vertical-align: text-bottom;
    }
    .name {
      display: inline-block;
      margin-right: 14px;
    }
    .follow {
      color: #f08f7a;
      cursor: pointer;
    }
    .heart {
      width: 18px;
      vertical-align: text-bottom;
      cursor: pointer;
    }
  }
  .cover {
    border: 1px solid #f08f7a;
  }
  h1.title {
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: justify;
    margin: 36px 15px 26px;
    span.category {
      letter-spacing: 1px;
      padding: 1px 6px;
      line-height: 36px;
      font-size: 14px;
      vertical-align: top;
    }

    a:focus {
      font-size: 26px;
      font-weight: 400;
      line-height: 36px;
    }

    span.category.cat-1 {
      background: #bff6ef;
    }

    span.category.cat-2 {
      background: #fdff9e;
    }

    span.category.cat-3 {
      background: #ffd6e8;
    }
  }
  p {
    font-size: 14px;
    padding: 5px;
  }
  .tag {
    font-size: 13px;
    line-height: 185%;
    text-align: center;
    letter-spacing: 1px;
    color: #f08f7a;
    background-color: transparent;
    margin: 6px 6px 0;
    border: 1px solid #f08f7a;
    border-radius: 50px;
  }
  .slick-slider {
    margin: 0 auto;
    clear: both;
    max-width: 800px;
    width: 100%;
    .slick-slide {
      height: auto;
      width: 100%;
      max-width: 800px;
      .content {
        img {
          top: 0;
          display: block;
          max-width: 800px;
          max-height: 500px;
          width: 100%;
          height: auto;
          object-fit: cover;
        }
      }
    }
    .slick-dots {
      bottom: 8px;
      height: 35px;
      li {
        button {
          border-radius: 50%;
          border: 1px solid #ffffff;
          width: 6px;
          height: 6px;
          padding: 3px;
        }
        button:before {
          content: '';
        }
      }
      li.slick-active button {
        background-color: #ffffff;
      }
    }
  }
}

.social-share {
  font-family: 'Ubuntu Condensed', '微軟正黑體', 'Microsoft JhengHei',
    'LiHei Pro', Arial, sans-serif;
  text-align: justify;
  line-height: 40px;
  padding: 0 5px;
  .date-text {
    font-size: 14px;
    margin-right: 4px;
  }
  .social-icons {
    display: inline-flex;
    float: right;
    div {
      line-height: 10px;
      margin: 5px;
      border: 1px #000000 solid;
      border-radius: 50%;
      padding: 5px;
      height: 28px;
      width: 28px;
      cursor: pointer;
    }
  }
}

.member-only-wrapper {
  position: relative;
  height: 130px;
  .member-only-cover {
    position: absolute;
    top: -120px;
    right: 0;
    left: 0;
    height: 250px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.8) 30%,
      #ffffff 100%
    );
    display: flex;
    // flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 50px;
  }
}

.article-content {
  @import 'froala-editor/css/froala_style.min.css';
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
  strong {
    color: unset;
  }
  max-width: 810px;
  margin: 15px auto;
  overflow-wrap: break-word;
  font-size: 18px;
  h2,
  h3,
  h4 {
    font-weight: 600 !important;
  }
  h2 {
    font-size: 1.35em !important;
  }
  h3 {
    font-size: 1.15em !important;
  }
  h4 {
    font-size: 1em !important;
  }
  img {
    height: auto !important;
  }
  @media only screen and (max-width: 700px) {
    margin: 15px 5%;
    img {
      width: 95% !important;
    }
    .fr-video {
      position: relative;
      overflow: hidden;
      padding-bottom: 56.25%;
      height: 0;
      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 90vw;
        height: 50.6vw;
      }
    }
  }
}
.article-content.big {
  font-size: 21px !important;
}
.article-content.small {
  font-size: 16px !important;
}

.article-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 15px auto;
  max-width: 810px;
  padding: 8px;
  background-color: rgb(220, 220, 220) !important;
  .tag-link {
    display: flex;
    margin: 5px 8px;
  }
}
</style>
