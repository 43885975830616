<template>
  <section id="pick-up-desktop" class="colomn is-one-quarter has-background-lookin-bg">
    <div class="pick-up-title home-block-title has-text-centered has-text-black">
      <h2><span>P</span>ick Up<span class="has-text-lookin-darker">特別企劃</span></h2>
    </div>
    <div v-if="pick_up" class="pick-ups">
      <template v-if="i < 5" v-for="(article, i) in pick_up">
        <div class="issue">
          <div class="recmd-img-container">
            <a
              @click.prevent="jumpByBrowser({
                name: 'article',
                params: {
                  channel: article.parent_path,
                  sub_channel: article.child_path,
                  id: article.id,
                  title: article.title
                }
              })"
            >
              <!-- <img :src="article.image_url || require('@/../images/default.jpg')" class="content-img" /> -->
              <LazyImage class="content-img" :src="article.image_url" />
            </a>
          </div>
          <h3 class="has-text-black">
            <a @click.prevent="jumpByBrowser({ name: 'new', query: { category: article.category.path } })">
              <span class="category" :class="cateClass(article.category.id)">{{ article.category.title }}</span>
            </a>
            <a
              @click.prevent="jumpByBrowser({
                name: 'article',
                params: {
                  channel: article.parent_path,
                  sub_channel: article.child_path,
                  id: article.id,
                  title: article.title
                }
              })"
              class="has-text-black"
            >
              {{ article.title }}
            </a>
          </h3>
        </div>
        <!-- <Advertisement v-if="i == 1" :id="26" :width="300" :height="250" /> -->
      </template>
    </div>
    <!-- <Advertisement :id="27" :width="300" :height="600" /> -->
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { categoryClass } from 'src/share';
import Advertisement from 'src/components/common/Advertisement';
import LazyImage from '../../components/common/LazyImage.vue';

export default {
  name: 'pick-up',
  props: ['isLoading', 'pick_up'],
  data() {
    return {};
  },
  components: { Advertisement, LazyImage },
  methods: {
    cateClass: (id) => categoryClass(id),
  },
  computed: {
    ...mapState(['isMobile']),
  },
};
</script>
<style lang="scss">
#pick-up-desktop {
  position: relative;
  width: 300px;
  max-width: 300px;
  margin-top: 104px;
  margin-left: 55px;
  padding: 0;
  background-color: #dcdcdc !important;
  .pick-up-title {
    position: absolute;
    top: -93px;
    left: 50%;
    transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    h2 {
      span:nth-child(1) {
        width: 36px;
      }
      span.has-text-lookin-darker {
        left: 96px;
      }
    }
  }
  .pick-ups {
    max-width: 300px;
    margin: 71px auto;
    .issue {
      height: 250px;
      max-width: 232px;
      margin: 20px auto;
      .recmd-img-container {
        border: 1px solid #ffa0b9;
        padding: 6px;
        height: 170px;
        img {
          height: 156px;
          width: 100%;
          margin: 0 auto;
          object-fit: cover;
        }
      }
      h3 {
        margin-top: 10px;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: justified;
        span.category {
          font-size: 13px;
          line-height: 185%;
          letter-spacing: 1px;
          padding: 1px 6px;
        }
      }
    }
  }
}
</style>
