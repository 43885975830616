<template>
  <div ref="infiniteWrapper">
    <div id="article-list">
      <div
        class="list-article"
        ref="articleRef"
        v-for="(article, index) in recommendArticleList"
        :key="article.id"
        @click="handleArticle(index, article.id)"
      >
        <p v-if="isMobile" class="author">
          <router-link to="">
            <img class="avatar" :src="authorAvatar(article.author_avatar)" />
            <span class="name has-text-black">{{ article.author_name }}</span>
          </router-link>
          <span class="date">{{ article.published_at }}</span>
        </p>
        <router-link
          class="cover img-container"
          :to="{
            path: `/${article.parent_path}/${article.child_path}/${article.id}`,
            params: {
              title: article.title
            }
          }"
        >
          <!-- <img :src="article.image_url" :alt="article.title" class="big-content-img" /> -->
          <LazyImage :src="article.image_url" :alt="article.title" class="big-content-img" />
        </router-link>
        <div class="a-info">
          <div v-if="!isMobile">
            <p class="author">
              <router-link to="">
                <img class="avatar" :src="authorAvatar(article.author_avatar)" />
                <span class="name has-text-black">{{ article.author_name }}</span>
              </router-link>
              <span class="date">{{ article.published_at }}</span>
            </p>
            <h2 class="title has-text-black">
              <span class="category has-text-black" :class="cateClass(article.category.id)">
                {{ article.category.title }}
              </span>
              <router-link
                :to="{
                  path: `/${article.parent_path}/${article.child_path}/${article.id}`,
                  params: {
                    title: article.title
                  }
                }"
              >
                {{ article.title }}
              </router-link>
            </h2>
            <p class="abstract">
              {{ article.abstract }}
            </p>
          </div>
          <p :class="isMobile ? 'has-text-centered tags' : 'tags'">
            <router-link
              v-for="tag in article.tags.slice(0, 3)"
              :key="tag"
              class="tag"
              :to="{ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } }"
            >
              #{{ tag }}
            </router-link>
          </p>
        </div>
        <h2 v-if="isMobile" class="title has-text-black">
          <span class="category has-text-black" :class="cateClass(article.category.id)">
            {{ article.category.title }}
          </span>
          <router-link
            :to="{
              path: `/${article.parent_path}/${article.child_path}/${article.id}`,
              params: {
                title: article.title
              }
            }"
          >
            {{ article.title }}
          </router-link>
        </h2>
      </div>
    </div>
    <div v-if="isShowFinishedTip" class="load_tip__finished">-已顯示全部-</div>
    <transition name="fade">
      <Loading v-show="isLoading" />
    </transition>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { categoryClass } from 'src/share'
import { throttle } from 'throttle-debounce'
// import HomeSlide from './HomePage/HomeSlide'
import Loading from 'src/components/common/Loading'
// import Advertisement from 'src/components/common/Advertisement'
import { personalizedRecommendation } from '../../api/article'
import LazyImage from '../components/common/LazyImage.vue'
import useObserver from '../../tools/useObserver'
import { isLookinApp } from '../../tools/bridge'

export default {
  data() {
    return {
      isLoading: false,
      isFinished: false,
      categories: [
        {
          key: 'topic',
          name: '話題'
        },
        {
          key: 'practical',
          name: '實用'
        },
        {
          key: 'experience',
          name: '體驗'
        }
      ],
      recommendArticleList: []
    }
  },
  components: { LazyImage, Loading },
  computed: {
    ...mapState(['isMobile']),
    authorAvatar: function () {
      return avatar => (avatar ? avatar : require('@/../images/editor.jpg'))
    },
    routeName() {
      return this.$route.name
    },
    isShowFinishedTip() {
      return isLookinApp && this.isFinished
    }
  },
  methods: {
    cateClass: id => categoryClass(id),
    // 获取首页个性化推荐
    async getRecommendFn() {
      this.isLoading = true
      // eslint-disable-next-line
      const params = { cookieToc: $cookies.get('device_id') || '', type: isLookinApp ? 1 : 0 }
      const { code, message, result } = await personalizedRecommendation(params)
      this.isLoading = false
      if (code === 200 && result) {
        this.recommendArticleList = result
        this.isFinished = true
        // 点击推荐触发事件
        try {
          new TrackEvent().setEvent('click_article_text', 'click', result.length, '', '')
        } catch (error) {
          console.log(error)
        }

        // 文章曝光事件
        this.$nextTick(() => {
          const articleRefs = this.$refs.articleRef
          if (articleRefs) {
            articleRefs.forEach((el, index) => {
              useObserver(el, () => {
                try {
                  new TrackEvent().setEvent('read_personalized_article', 'read', result[index].id, index + 1, '')
                } catch (error) {
                  console.log(error)
                }
              })
            })
          }
        })
      } else {
        console.error(message)
      }
    },
    scrollHandler: throttle(800, function () {
      console.log(this.$refs.infiniteWrapper)
      let offSet =
        this.$refs.infiniteWrapper.scrollHeight -
        document.querySelector('html').scrollTop -
        document.documentElement.clientHeight
      if (!this.isLoading && offSet < 100) {
        this.isLoading = true
        // Load more page by state.current_page
        // this.apiCall()
      }
    }),
    handleArticle(index, id) {
      try {
        new TrackEvent().setEvent('click_personalized_article', 'click', id, index + 1, '')
      } catch (error) {
        console.log(error)
      }
    }
  },
  mounted() {
    // window.addEventListener('scroll', this.scrollHandler)
    // this.apiCall()
    this.getRecommendFn()
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.scrollHandler)
  }
}
</script>
