<template>
  <div class="article-item" ref="articleRef">
    <div class="info-mb">
      <p>
        <img class="avatar" :src="article.author_avatar || defaultAvatar" alt="" />
        <span class="name">{{ article.author_name }}</span>
      </p>
      <p class="time">{{ article.published_at }}</p>
    </div>
    <div class="left" @click="handleArticle">
      <!-- <img :src="article.image_url || defaultImage" alt="" /> -->
      <LazyImage :src="article.image_url" :alt="article.title" />
    </div>
    <div class="right">
      <div class="info">
        <p>
          <img class="avatar" :src="article.author_avatar || defaultAvatar" alt="" />
          <span class="name">{{ article.author_name }}</span>
        </p>
        <p class="time">{{ article.published_at }}</p>
      </div>
      <h3 class="t" @click="handleArticle">
        <span class="category" :class="`cat-${article.category.id}`">{{ article.category.title }}</span>
        {{ article.title }}
      </h3>
      <p class="desc textOVerThree">
        {{ article.abstractText }}
        <!-- {{ articleContent }} -->
      </p>
      <div class="right-tag">
        <button class="btn" v-for="(tag, index) in article.tags" :key="index" @click="handleTag(tag)">
          #{{ tag }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import avatarImg from '../../../images/user_default.png';
// import defaultImg from '../../../images/default.jpg'
import useObserver from '../../../tools/useObserver';
import LazyImage from '../common/LazyImage.vue';

export default {
  name: 'ArticleItem',
  props: {
    article: {
      type: Object,
      default: () => {},
    },
    index: {
      require: true,
      type: Number,
    },
    articleId: {
      require: true,
      type: Number,
    },
  },
  components: { LazyImage },
  data() {
    return {
      defaultAvatar: avatarImg,
      // defaultImage: defaultImg
    };
  },
  mounted() {
    // 推荐文章曝光事件
    this.$nextTick(() => {
      const { articleRef } = this.$refs;
      useObserver(articleRef, () => {
        try {
          new TrackEvent().setEvent('read_recommend_article', 'read', this.articleId, this.index + 1, '');
        } catch (error) {
          console.log(error);
        }
      });
    });
  },
  computed: {
    articleContent() {
      return this.article.content?.replace(/<\/?[^>]*>|(\n|\t|\r)|(\s)/g, '') ?? '';
    },
  },
  methods: {
    handleArticle() {
      try {
        new TrackEvent().setEvent('click_recommend_article', 'click', this.articleId, this.index + 1, '');
      } catch (error) {
        console.log(error);
      }
      this.jumpByBrowser({
        path: `/${this.article.parent_path}/${this.article.child_path}/${this.article.id}`,
        params: {
          title: this.article.title,
        },
      });
    },
    handleTag(tag) {
      this.jumpByBrowser({ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } });
    },
  },
};
</script>

<style lang="scss" scoped>
.textOVerThree {
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* 这里是超出几行省略 */
  overflow: hidden;
}

.article-item {
  display: flex;
  align-items: center;
  margin: 20px 0;
  .info-mb {
    display: none;
  }
  .left {
    width: 416px;
    height: 266px;
    cursor: pointer;
    img {
      border: 1px solid #ffa0b9;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &:hover {
      transform: scale(1.05);
      transition: all 0.5s;
    }
  }
  .right {
    margin-left: 20px;
    flex: 1;
    .info {
      display: flex;
      justify-content: space-between;
      p {
        display: flex;
        align-items: center;
        .avatar {
          width: 35px;
          height: 35px;
          border-radius: 999px;
          object-fit: cover;
        }
        .name {
          font-size: 17px;
          color: #000000;
          margin-left: 10px;
        }
      }
      .time {
        font-size: 17px;
        color: #000000;
      }
    }
    .t {
      font-size: 20px;
      margin: 10px 0 25px;
      cursor: pointer;
      word-break: break-all;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2; /* 这里是超出几行省略 */
      overflow: hidden;
      .category {
        padding: 2px 6px;
        font-size: 13px;
        background-color: #fdff9e;
        margin-right: 5px;
        vertical-align: middle;
        &.cat-1 {
          background: #bff6ef;
        }
        &.cat-2 {
          background: #fdff9e;
        }
        &.cat-3 {
          background: #ffd6e8;
        }
      }
      &:hover {
        color: #f08f7a;
      }
    }
    .desc {
      font-size: 16px;
      line-height: 24px;
      color: #989595;
      word-break: break-all;
    }
    .right-tag {
      margin-top: 10px;
      max-height: 35px;
      overflow: hidden;
      .btn {
        font-size: 13px;
        color: #ffa0b9;
        margin: 5px;
        padding: 4px 10px;
        border: 1px solid #ffa0b9;
        border-radius: 50px;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        &:hover {
          background-color: #ffa0b9;
          color: white;
        }
      }
    }
  }
}

// 移动端样式
@media screen and (max-width: 768px) {
  .article-item {
    display: block;
    .info-mb {
      display: flex;
      justify-content: space-between;
      margin: 10px;
      p {
        display: flex;
        align-items: center;
        .avatar {
          width: 28px;
          height: 28px;
          border-radius: 999px;
          object-fit: cover;
        }
        .name {
          font-size: 14px;
          color: #000000;
          margin-left: 10px;
        }
      }
      .time {
        font-size: 14px;
        color: #000000;
      }
    }
    .left {
      width: 100%;
      height: 234px;
    }
    .right {
      margin: 10px;
      .info {
        display: none;
      }
      .t {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 15px;
      }
    }
  }
}
</style>
