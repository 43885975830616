<template>
  <section id="recommend" :class="isMobile ? '' : 'column'">
    <div class="home-block-title has-text-centered has-text-black" ref="titleRef">
      <h2><span>R</span>ecommend<span class="has-text-lookin-darker">推薦文章</span></h2>
    </div>
    <div class="issues">
      <div v-if="i < itemsToShow" class="issue" v-for="(article, i) in recommends" ref="articleRef">
        <a
          class="img-container"
          @click="jumpArticle(article, i)"
        >
          <!-- <img :src="article.image_url || require('@/../images/default.jpg')" class="content-img" /> -->
          <LazyImage :src="article.image_url" class="content-img" />
        </a>
        <div class="slide-text">
          <p v-if="isMobile" class="has-text-centered">
            <a
              v-for="tag in article.tags.slice(0, 3)"
              :key="tag"
              class="tag"
              @click.prevent="jumpByBrowser({ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } })"
              >#{{ tag }}</a
            >
          </p>
          <p :class="isMobile ? 'has-text-centered has-text-lookin-darker' : 'has-text-lookin-darker'">
            <a @click.prevent="jumpByBrowser({ name: 'new', query: { category: article.category.path } })">
              <span class="category has-text-black" :class="cateClass(article.category.id)">{{
                article.category.title
              }}</span>
            </a>
            <span class="date-author">{{ article.published_at }} ‧ {{ article.author_name }}</span>
          </p>
          <a
            @click="jumpArticle(article, i)"
          >
            <h3 class="title">{{ article.title }}</h3>
          </a>
          <p v-if="!isMobile">
            <a
              v-for="tag in article.tags.slice(0, 3)"
              :key="tag"
              class="tag"
              @click.prevent="jumpByBrowser({ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } })"
              >#{{ tag }}</a
            >
          </p>
        </div>
      </div>
    </div>
    <button
      v-if="showMore"
      class="load-more has-text-white has-background-black has-text-centered"
      @click="showMore = false"
    >
      LOAD MORE
    </button>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { categoryClass } from 'src/share';
import LazyImage from '../../components/common/LazyImage.vue';
import useObserver from '../../../tools/useObserver';

export default {
  props: ['isLoading', 'recommends'],
  components: { LazyImage },
  data() {
    return {
      showMore: true,
    };
  },
  methods: {
    cateClass: (id) => categoryClass(id),
    jumpArticle(article, i) {
      try {
        new TrackEvent().setEvent('click_home_recommend_article', 'click', article.id, i + 1, '');
      } catch (error) {}
      this.jumpByBrowser({
        path: `/${article.parent_path}/${article.child_path}/${article.id}`,
        params: {
          title: article.title,
        },
      });
    },
    exposureArticle() {
      const { articleRef } = this.$refs;
      if (articleRef) {
        articleRef.forEach((el, i) => {
          useObserver(el, () => {
            try {
              new TrackEvent().setEvent('read_home_recommend_article', 'read', this.recommends[i].id, i + 1, '');
            } catch (error) {}
          });
        });
      }
    },
  },
  computed: {
    ...mapState(['isMobile']),
    itemsToShow() {
      if (this.showMore) {
        return this.isMobile ? 5 : 6;
      }
      return this.isMobile ? 10 : 12;
    },
  },
  mounted() {
    useObserver(this.$refs.titleRef, () => {
      try {
        new TrackEvent().setEvent('exposure_home_recommend', 'read', this.recommends.length, null, null);
      } catch (e) {}
    });

    this.exposureArticle();
  },
};
</script>

<style lang="scss">
@media screen and (min-width: 1200px) {
  #recommend {
    max-width: 830px;
    h2 {
      z-index: 1;
      &::after {
        content: '';
        height: 8px;
        background: #ffa0b9;
        position: absolute;
        left: 6px;
        right: 7px;
        bottom: 8px;
        z-index: -1;
      }
    }
    .issues {
      width: 100%;
      .issue {
        display: flex;
        height: 260px !important;
        max-width: 830px !important;
        a.img-container {
          flex-grow: 0;
          flex-shrink: 0;
          height: 100%;
          // max-width: 350px;
          width: 350px;
        }
        .slide-text {
          flex-grow: 0;
          flex-shrink: 0;
          max-width: 380px;
          margin: 0 20px;
          h3.title {
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 29px;
            text-align: justify;
            margin: 0 5px 25px;
          }
          p {
            padding: 12px 0;
            span.category {
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 24px;
              text-align: center;
              letter-spacing: 1px;
            }
            span.date-author {
              margin-left: 12px;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 10px;
              letter-spacing: 1px;
            }
          }
        }
      }
    }
  }
}

#recommend {
  padding-bottom: 100px;
  h2 {
    span:nth-child(1) {
      width: 36px;
    }
    span.has-text-lookin-darker {
      left: 96px;
    }
  }
  .issues {
    width: 92%;
    margin: 0 4%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .issue {
      height: 435px;
      max-width: 375px;
      margin: 20px;
      img {
        height: 216px;
        // width: 100%;
        width: 375px;
        border: 1px solid #ffa0b9;
        margin: 0 auto;
        object-fit: cover;
      }
      h3.title {
        font-size: 16px;
        line-height: 140%;
        text-align: justify;
        margin: 0 5px 70px;
      }
      p {
        font-weight: normal;
        font-size: 14px;
        line-height: 71%;
        letter-spacing: 1px;
        padding: 5px;
        span.category {
          font-size: 13px;
          line-height: 185%;
          letter-spacing: 1px;
          padding: 1px 6px;
        }
        span.date-author {
          font-family: 'Ubuntu Condensed', '微軟正黑體', 'Microsoft JhengHei', 'LiHei Pro', Arial, sans-serif;
          font-weight: normal;
          font-size: 14px;
          line-height: 71%;
          letter-spacing: 1px;
        }
        .tag {
          font-size: 13px;
          line-height: 185%;
          text-align: center;
          letter-spacing: 1px;
          color: #ffa0b9;
          background-color: transparent;
          margin: 6px 6px 0;
          border: 1px solid #ffa0b9;
          border-radius: 50px;
        }
      }
    }
  }
}
</style>
