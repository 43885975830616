<template>
  <div id="article-container" class="columns" ref="infiniteWrapper">
    <div class="column articles-column">
      <div id="first-wrapper" ref="articleContentRef">
        <Article v-if="!isLoadingPre && article" id="first-article" :article="article" @showModal="modal = true" />
        <h3 class="p-denied" v-if="permissionDenied">無法預覽文章，請確認預覽密碼後重新載入頁面。</h3>
      </div>
      <!-- <InterVideo v-if="inter_video" :video="inter_video" /> -->

      <!-- <div v-if="isMobile" class="mobile-ad">
        <Advertisement :id="38" :width="300" :height="250" />
      </div>
      <div v-else class="ads">
        <Advertisement :id="33" :width="300" :height="250" />
        <Advertisement :id="34" :width="300" :height="250" />
      </div> -->
      <!-- 推荐文章 -->
      <div v-if="waterfallsArticleList && waterfallsArticleList.length > 0">
      <hr class="divider" />
        <div class="home-block-title has-text-centered has-text-black" ref="recommendTiteRef">
          <h2 style="margin-top: 0"><span>R</span>ecommend<span class="has-text-lookin-darker">推薦文章</span></h2>
        </div>
        <article-item
          v-for="(item, index) in waterfallsArticleList"
          :key="index"
          :article="item"
          :index="index"
          :articleId="article_id"
        />
      </div>
    </div>
    <div id="right-sidebar" class="column is-one-quarter has-background-lookin-bg" v-if="!isMobile"></div>
    <!-- <RightSideBar v-if="!isMobile" /> -->
    <transition v-if="canLoadMore" name="fade">
      <Loading v-show="isLoading" />
    </transition>
    <Modal :open="modal" :title="'請先登入'" :content="'這是會員專屬功能喔！'" :btnWord="'好的'" @close="closeModal" />
    <b-modal
      v-model="enter_passcode"
      custom-class="lookin-modal"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      :can-cancel="false"
      aria-role="dialog"
      aria-modal
    >
      <div class="modal-card">
        <section class="modal-card-body has-text-black">
          <img
            :src="require('@/../images/icon-close.svg')"
            alt="Close"
            class="tail-icon close"
            @click="
              enter_passcode = false
              permissionDenied = true
            "
          />
          <h3>請輸入預覽密碼</h3>
          <b-input v-model="passcode" ref="passcode" @keyup.enter.native="submitPassCode" rounded> </b-input>
          <p v-if="wrong_passcode" class="has-text-danger">密碼不對喔！</p>
        </section>
        <footer class="modal-card-foot">
          <b-button @click="submitPassCode">送出</b-button>
        </footer>
      </div>
    </b-modal>
    <div id="ig-embed"></div>
    <img
      class="answer-icon"
      src="../../../images/coupon/answer.png"
      alt=""
      v-if="isShowAnswerIcon"
      @click="handleAnswer"
    />
    <point-modal
      v-show="isPointModal"
      :type="pointType"
      :point="pointNum"
      :info="prizeInfoModal"
      :specialImgUrl="pointModalIcon"
      @closeModal="closePointModal"
      @gotoExchange="gotoExchange"
    />
    <read-modal :visible.sync="isShowReadModal" title="閱讀完成">
      <template #content>
        <success-icon />
        <p style="font-size: 16px; color: #404040; margin: 14px 0 27px">{{ readModalMsg }}</p>
        <b-button
          type="is-primary"
          rounded
          expanded
          style="background: #f08f7a; color: #ffffff"
          @click="$router.back()"
        >
          去使用
        </b-button>
      </template>
    </read-modal>
  </div>
</template>
<script>
// import postscribe from 'postscribe'
import { mapActions, mapState, mapGetters } from 'vuex';
// import { categoryClass, resizeFont } from 'src/share'
import Modal from 'src/components/dialog/Modal';
import Loading from 'src/components/common/Loading';
import Advertisement from 'src/components/common/Advertisement';
import { throttle } from 'throttle-debounce';
// import PointModal from 'src/components/haveToPoint/pointModal'
import PointModal from 'src/components/haveToPoint/pointModal.vue';
import InterVideo from './InterVideo';
import Article from './Article';
import { getPointApi } from '../../../api/yellowStrip';
import { UPDATE_YELLOW_TIP, UPDATE_SHOW_YELLOW_TIP } from '../../../store/mutation-types';
import ArticleItem from '../../components/article/Item.vue';
import { waterfallsRecommendArticle } from '../../../api/article';
// import apis from '../../../api'
// import RightSideBar from 'src/components/layout/RightSideBar'
import useObserver from '../../../tools/useObserver';
import ReadModal from '../../components/dialog';
import SuccessIcon from '../../components/coupon/Icon.vue';
import { couponReading } from '../../../api/storeManager';

export default {
  name: 'InfiniteArticles',
  data() {
    return {
      isLoadingPre: true,
      isLoading: false,
      send2ndPV: false,
      modal: false,
      tagNumber: 0,
      commentsKey: 0,
      title: this.$route.params.title,
      link: null,
      article_id: null,
      passcode: null,
      enter_passcode: false,
      wrong_passcode: false,
      permissionDenied: false,
      retry: 0,
      isPointModal: false, // 是否显示得点模态框
      pointType: '', // 得点模态框类型 read 阅读得点   special 特殊得点
      pointNum: 0, // 得点数 普通默认1， 特殊接口返回
      pointValve: false, // 得点阀门，默认关闭
      firstTop: 0, // 某篇文章首次高度
      articleCount: 0, // 记录文章序号
      ordinaryPointArr: [], // 普通localStorage得点列表
      specialPointArr: [], // 特殊得点localStorage列表
      pointModalIcon: '',
      lastArticleId: this.$route.params.id || 0, // 瀑布流文章id
      waterfallsArticleList: [], // 瀑布流推荐文章列表
      isLoadMore: true, // 是否加载下一页
      progress: 0, // 文章浏览进度
      progressFlg: {
        60: true,
        70: true,
        80: true,
        90: true,
        100: true,
      },
      isShowReadModal: false,
      isShowAnswerIcon: false,
      readModalMsg: '恭喜你，票券已生效',
    };
  },
  metaInfo() {
    return {
      title: this.fullTitle,
    };
  },
  components: {
    Article, Modal, Advertisement, InterVideo, Loading, PointModal, ArticleItem, ReadModal, SuccessIcon,
  },
  watch: {
    isAuthed(val) {
      if (this.article && this.article.member_only) {
        this.isLoadingPre = true;
        this.$store.dispatch('loadArticle', this.token).then(() => {
          this.isLoadingPre = false;
          this.commentsKey += 1;
        });
      }
      this.commentsKey += 1;
    },
  },
  computed: {
    ...mapState([
      'token',
      'isMobile',
      'article',
      'articles',
      'channels',
      'user',
      'inter_video',
      'user',
      'recommends',
      'prizeInfoModal',
    ]),
    ...mapGetters(['isAuthed']),
    filteredArticles() {
      return this.articles ? this.articles.filter((item) => item.id !== this.article.id) : false;
    },
    canLoadMore() {
      return this.$route.name != 'preview' && (this.isAuthed || !(this.article && this.article.member_only));
    },
    fullTitle() {
      return this.title ? `${this.title} － COOL SIS 酷女孩` : document.title;
    },
    ordinaryFilterPoint() {
      // 符合条件 item 不弹窗，反之弹窗
      return this.ordinaryPointArr.find((item) => (
        (item.userId == +$cookies.get('user_id') || item.deviceId == $cookies.get('device_id'))
          && item.articleId == this.article_id
      ));
    },
    specialFilterPoint() {
      return this.specialPointArr.find((item) => (
        item.userId == +$cookies.get('user_id')
          && item.deviceId == $cookies.get('device_id')
          && item.articleId == this.article_id
      ));
    },
    getNextDate() {
      const t = new Date();
      t.setDate(t.getDate() + 1);
      t.setHours(0);
      t.setMinutes(0);
      t.setSeconds(0);
      return t;
    },
    articleContentHeight() {
      if (this.$refs.articleContentRef) {
        return this.$refs.articleContentRef.scrollHeight;
      }
      return 0;
    },
  },
  methods: {
    ...mapActions(['setLoginDrawer', 'prizeInfo']),
    // loadMore() {
    //   this.isLoading = true
    //   var tag = this.article.tags[this.tagNumber]
    //   if (tag === undefined && this.articles) {
    //     tag = this.articles.slice(-1).pop().tags[0]
    //   }
    //   this.$store.dispatch('loadRelatedArticles', this.token).then(() => {
    //     this.isLoading = false
    //     this.tagNumber += 1
    //   })
    // },
    reloadInstagram() {
      if (window.instgrm != undefined) {
        window.instgrm.Embeds.process();
      }
    },
    scrollHandler: throttle(800, function () {
      const percentage = this.scrollTop() / this.articleContentHeight;
      const progress = Math.floor(percentage * 100);

      // 添加统计代码，浏览文章进度
      if (this.progress < progress) {
        this.progress = progress;
        if (progress >= 60 && this.progressFlg[60]) {
          try {
            new TrackEvent().setEvent('read_article_text', 'read', this.article_id, '60%', null);
          } catch (e) {
            console.log(e);
          }
          this.progressFlg[60] = false;
        }
        if (progress >= 70 && this.progressFlg[70]) {
          try {
            new TrackEvent().setEvent('read_article_text', 'read', this.article_id, '70%', null);
          } catch (e) {
            console.log(e);
          }
          this.progressFlg[70] = false;
        }
        if (progress >= 80 && this.progressFlg[80]) {
          try {
            new TrackEvent().setEvent('read_article_text', 'read', this.article_id, '80%', null);
          } catch (e) {
            console.log(e);
          }
          this.progressFlg[80] = false;
        }
        if (progress >= 90 && this.progressFlg[90]) {
          try {
            new TrackEvent().setEvent('read_article_text', 'read', this.article_id, '90%', null);
          } catch (e) {
            console.log(e);
          }
          this.progressFlg[90] = false;
        }
        if (progress >= 100 && this.progressFlg[100]) {
          try {
            new TrackEvent().setEvent('read_article_text', 'read', this.article_id, '100%', null);
          } catch (e) {
            console.log(e);
          }
          this.progressFlg[100] = false;
        }
      }

      // 第一篇文章， 判断是否滑动到文章50%
      if (progress >= 50) {
        if (!this.pointValve) {
          // 打开阀门 只执行一次
          this.pointValve = true;
          // 添加统计代码，某篇文章到50%
          try {
            new TrackEvent().setEvent('read_the_article', '50%', null, null, null);
          } catch (e) {
            console.log(e);
          }

          // 没登录
          const token = window.localStorage.getItem('auth-token');
          const closeMonitor = window.sessionStorage.getItem('closeMonitor');
          if (!token && !this.user && !closeMonitor) {
            // 阅读文章过半触发, 延迟触发
            setTimeout(() => {
              // 有点数，显示黄条
              this.getPoint();
            }, 1000);
          }
          // 文章票券任务
          const { source } = this.$route.query;
          // 阅读文章激活票券
          const couponRead = window.sessionStorage.getItem('coupon_read');
          if (couponRead) {
            // 用完删除
            window.sessionStorage.removeItem('coupon_read');
            const { id, articleId, type } = JSON.parse(couponRead);
            // type 阅读类型：0 阅读文章激活票券； 1 答题阅读文章；
            type === 0 ? this.acouponReadingFn({ id, articleId }) : this.openAnswerIcon();
          } else {
            // 商品文章得票券任务，不弹得点框
            if (source == 'peizeTask') return;
            // 得点弹框
            this.pointModalFn(this.article);
          }
        }
      }

      // if (
      //   this.scrollTop() + 1200 >= this.contentHeight() &&
      //   this.isLoading == false &&
      //   !this.articles &&
      //   this.canLoadMore
      // ) {
      //   this.loadMore()
      // }

      if (this.scrollTop() + 1200 >= this.contentHeight() && !this.isLoading && this.isLoadMore) {
        // console.log(this.lastArticleId, '底部')
        if (this.lastArticleId) {
          this.getWaterfallsArticleFn(this.lastArticleId);
        }
      }

      this.link = this.article.link;
      this.article_id = this.article.id;
      this.title = this.article.title;
      if (this.articles) {
        const i = this.currentArticleIndex() - 1;
        if (i >= 0) {
          this.link = this.filteredArticles[i].link;
          this.article_id = this.filteredArticles[i].id;
          this.title = this.filteredArticles[i].title;
        }

        // this.$nextTick(() => {
        //   // 获取文章dom
        //   const articleDom = document.querySelector(`div[id=articles-${this.article_id}]`)
        //   if (articleDom) {
        //     if (this.scrollTop() - this.firstTop >= articleDom.offsetHeight / 2) {
        //       if (this.articleCount === i) {
        //         // 添加统计代码，某篇文章到50%
        //         try {
        //           new TrackEvent().setEvent('read_the_article', '50%', null, null, null)
        //         } catch (e) {
        //           console.log(e)
        //         }
        //         // 没登录
        //         const token = window.localStorage.getItem('auth-token')
        //         const closeMonitor = window.sessionStorage.getItem('closeMonitor')
        //         if (!token && !this.user && !closeMonitor) {
        //           // 阅读文章过半触发, 延迟触发
        //           setTimeout(() => {
        //             this.getPoint()
        //           }, 2000)
        //         }
        //         this.articleCount++

        //         // 瀑布流推荐文章浏览
        //         try {
        //           new TrackEvent().setEvent('read_recommend_article', 'read', this.article_id, this.articleCount, null)
        //         } catch (e) {
        //           console.log(e)
        //         }

        //         // 得点弹框
        //         this.pointModalFn(this.filteredArticles[i])
        //       }
        //     }
        //   }
        // })
      }

      if (this.link != window.location.pathname) {
        // 记录首次滑动到某篇的高度
        this.firstTop = this.scrollTop();
        // 关闭得点框
        this.closePointModal('auto');
        // 換文章了
        history.pushState({}, null, this.link);
        this.$nextTick(() => {
          // 不指定 send_to 就是送到兩組 GA
          this.$gtag.pageview({
            page_path: this.link,
            page_title: this.fullTitle,
            page_location: window.location.href,
          });
          this.$store.dispatch('click', { media: 'article', id: this.article_id });
          this.send2ndPV = false;
        });
      } else if (!this.send2ndPV) {
        this.$nextTick(() => {
          this.$gtag.pageview({
            page_path: this.link,
            page_title: this.fullTitle,
            page_location: window.location.href,
            send_to: process.env.GA_TRACKING_ID,
          });
          this.$store.dispatch('click', { media: 'article', id: this.article_id });
          this.send2ndPV = true;
        });
      }
    }),
    scrollTop() {
      return document.querySelector('html').scrollTop;
    },
    contentHeight() {
      if (this.$refs.infiniteWrapper) {
        return this.$refs.infiniteWrapper.scrollHeight;
      }
    },
    firstArticleHeight() {
      if (document.querySelector('#first-article')) {
        return document.querySelector('#first-article').scrollHeight;
      }
    },
    totalArticlesHeight(i) {
      const sum = this.filteredArticles.slice(0, i).reduce((sumHeight, article, index) => {
        const articleHeight = document.querySelector(`#articles-${article.id}`).scrollHeight;
        return sumHeight + articleHeight;
      }, 0);
      return sum;
    },
    currentArticleIndex() {
      if (this.scrollTop() < this.firstArticleHeight()) {
        return -1;
      }
      let i = 1;
      while (this.scrollTop() > this.firstArticleHeight() + this.totalArticlesHeight(i)) {
        i++;
      }
      return i;
    },
    closeModal() {
      this.modal = false;
      this.setLoginDrawer(true);
    },
    submitPassCode() {
      this.retry++;
      this.enter_passcode = false;
      if (this.retry == 5) {
        this.permissionDenied = true;
      } else {
        this.$store
          .dispatch('previewArticle', { passcode: this.passcode })
          .then(() => {
            this.isLoadingPre = false;
            if (window.instgrm !== undefined) window.instgrm.Embeds.process();
            // this.$nextTick(() => {
            //   postscribe('#ig-embed', `<script async src="https://www.instagram.com/embed.js"><\/script>`)
            // })
          })
          .catch((error) => {
            this.enter_passcode = true;
            this.$nextTick(() => {
              this.wrong_passcode = true;
              this.$refs.passcode.focus();
            });
          });
      }
    },
    closePointModal(type) {
      if (type !== 'auto') {
        $cookies.set('close_point_modal', true, this.getNextDate);
      }
      this.isPointModal = false;
    },
    gotoExchange() {
      const { article_type: articleType, special_reward_jump_link: link } = this.article;
      if (articleType === 'SpecialArticleReadingReward') {
        if (link) {
          // 有链接设置跳转链接，反之跳转到赠品兑换页
          window.location.href = link;
        } else {
          this.$router.push('/prizes');
        }
      } else {
        /**
         * 跳转赠品兑换页
         */
        // this.$router.push('/prizes')
        // this.$router.push('/prizes/154')
        window.location.href = '/nuxt/prizes/195';
      }
    },
    pointModalFn({ article_point: articlePoint, special_reward_image_url: imgUrl, article_type: articleType }) {
      /**
       * article_type 得点类型
       * null 普通
       * SpecialArticleReadingReward 特殊
       *
       * article_point
       * null 普通
       * 特殊返回点数
       */
      // const { article_point: articlePoint, special_reward_image_url: imgUrl, article_type: articleType } = this.article
      if (articleType === 'SpecialArticleReadingReward') {
        if (!this.specialFilterPoint) {
          // console.log('特殊得点', articlePoint)
          if (imgUrl) {
            // 有图片设置图片
            this.pointModalIcon = imgUrl;
          }
          const year = new Date().getTime() + 31536000000;
          // 弹出特殊得点
          this.pointType = 'special';
          this.pointNum = +articlePoint;
          this.isPointModal = true;
          // 设置localStorage，记录文章id,用户id
          this.specialPointArr.push({
            userId: +$cookies.get('user_id'),
            deviceId: $cookies.get('device_id'),
            articleId: this.article_id,
            time: year,
          });
          window.localStorage.setItem('special_point', JSON.stringify(this.specialPointArr));
        }
      } else {
        // 判断localStorage里是否存在和用户id，文章id相等，否弹框普通得点每日10篇
        // 查找这个用户id下文章数量
        let count = 0;
        const userId = +$cookies.get('user_id');
        const deviceId = $cookies.get('device_id');
        const closePointModal = Boolean($cookies.get('close_point_modal'));
        if (userId) {
          // 已登录
          this.ordinaryPointArr.map((item) => {
            if (item.userId === userId) {
              count++;
            }
          });
        } else {
          this.ordinaryPointArr.map((item) => {
            if (item.deviceId === deviceId) {
              count++;
            }
          });
        }
        // console.log(this.ordinaryFilterPoint, count, closePointModal)
        if (!this.ordinaryFilterPoint && count < 10 && !closePointModal) {
          // console.log('滑动到文章50%')
          // const day = new Date().getTime() + 86400000
          const day = new Date().getDate();
          const articleObj = {
            articleId: this.article_id,
            time: day,
          };
          // 已登录 设置userid， 未登入设置deviceid
          if (userId) {
            articleObj.userId = userId;
          } else {
            articleObj.deviceId = deviceId;
          }
          // 弹出普通得点
          this.pointType = 'read';
          this.pointNum = 1;
          this.isPointModal = true;
          // 设置localStorage，记录文章id,用户id
          this.ordinaryPointArr.push(articleObj);
          window.localStorage.setItem('read_point', JSON.stringify(this.ordinaryPointArr));
        }
      }
    },
    async getPoint() {
      try {
        const fd = new FormData();
        // eslint-disable-next-line
        fd.append('deviceId', $cookies.get('device_id'))
        const data = await getPointApi(fd);
        const { code, message, result } = data.data;
        if (code === 200) {
          if (result.point > 0) {
            this.$store.commit(UPDATE_SHOW_YELLOW_TIP, true);
            this.$store.commit(UPDATE_YELLOW_TIP, { point: result.point, code: result.webCode });
            try {
              new TrackEvent().setEvent('exposure_articleYellow', '', '', null, null);
            } catch (error) {
              console.log(error);
            }
          }
        } else {
          console.log(message);
        }
      } catch (error) {
        console.warn(error);
      }
    },
    async getWaterfallsArticleFn(articleId, loading = true) {
      try {
        this.isLoading = loading;
        const { code, result } = await waterfallsRecommendArticle(articleId);
        if (code === 200 && result) {
          // 本篇文章id
          const firstArticleId = Number(this.$route.params.id) || 0;
          result.forEach((item) => {
            // 去除推荐的本篇文章和其他重复的文章
            if (firstArticleId !== item.id && !this.waterfallsArticleList.some((e) => e.id == item.id)) {
              this.waterfallsArticleList.push(item);
            }
          });
          // 获取推荐文章列表 最后一篇文章id
          this.lastArticleId = result[result.length - 1]?.id ?? 0;
          this.$nextTick(() => {
            const recommendDom = this.$refs.recommendTiteRef;
            if (recommendDom) {
              useObserver(recommendDom, () => {
                try {
                  new TrackEvent().setEvent('recommend_article_text', 'read', articleId, result.length, '');
                } catch (error) {
                  console.log(error);
                }
              });
            }
          });
          // 推荐文章的加载次数
          try {
            new TrackEvent().setEvent('recommen_article_record', 'recommend', articleId, result.length, null);
          } catch (error) {
            console.log(error);
          }
        } else {
          this.isLoadMore = false;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async acouponReadingFn(data) {
      // { id: this.couponId, articleId: this.info.id }
      // 激活票券
      const { code, message } = await couponReading(data);
      if (code === 200) {
        // 弹框
        this.isShowReadModal = true;
      } else if (code === 1511) {
        this.readModalMsg = message;
        // 弹框
        this.isShowReadModal = true;
      }
    },
    openAnswerIcon() {
      this.isShowAnswerIcon = true;
    },
    handleAnswer() {
      this.isShowAnswerIcon = false;
      this.$router.back();
    },
  },
  mounted() {
    if (this.$route.name == 'preview') {
      this.$store
        .dispatch('previewArticle', { passcode: this.passcode })
        .then(() => {
          this.isLoadingPre = false;
          if (window.instgrm !== undefined) window.instgrm.Embeds.process();
          // this.$nextTick(() => {
          //   postscribe('#ig-embed', `<script async src="https://www.instagram.com/embed.js"><\/script>`)
          // })
        })
        .catch((error) => {
          this.enter_passcode = true;
          this.$nextTick(() => {
            this.$refs.passcode.focus();
          });
        });
    } else {
      this.$store.dispatch('loadArticle').then(() => {
        this.isLoadingPre = false;
        this.$store.dispatch('click', { media: 'article', id: this.article.id });
        if (this.canLoadMore) {
          // window.addEventListener('scroll', this.scrollHandler)
        }
        // a little hacky, load IG emged.js anyway to prevent issue:
        // Failed to execute 'write' on 'Document'
        if (window.instgrm !== undefined) window.instgrm.Embeds.process();
        // this.$nextTick(() => {
        //   postscribe('#ig-embed', `<script async src="https://www.instagram.com/embed.js"><\/script>`)
        // })
      });
    }

    // 获取得点localStorage
    const readNow = new Date().getDate();
    const specialNow = new Date().getTime();
    const readPoint = JSON.parse(window.localStorage.getItem('read_point')) || [];
    const specialPoint = JSON.parse(window.localStorage.getItem('special_point')) || [];
    // time和now不相等证明超过了一天，过滤掉
    const readPointFilter = readPoint.filter((item) => item.time === readNow);
    // time和now超过1年，过滤掉
    const specialPointFilter = specialPoint.filter((item) => item.time > specialNow);
    this.ordinaryPointArr = readPointFilter;
    this.specialPointArr = specialPointFilter;

    // 仓库没有则获取得点弹框赠品信息
    if (!this.prizeInfoModal) {
      // this.prizeInfo(1);
    }

    // articleId要为数字，否则不获取推荐文章
    if (this.lastArticleId && !isNaN(+this.lastArticleId)) this.getWaterfallsArticleFn(this.lastArticleId, false);
    // const params = {
    //   id: id,
    //   auth_token: this.token || undefined,
    //   channel: 'fashion',
    //   sub_channel: 'luxury'
    // }
    // apis.getRelatedArticles(params).then(response => {
    //   console.log(response, '#')
    //   if (response.data) {
    //     this.waterfallsArticleList = response.data
    //     const count = response.data.length
    //     // 添加曝光事件
    //     this.$nextTick(() => {
    //       const recommendDom = this.$refs.recommendTiteRef
    //       if (recommendDom) {
    //         useObserver(recommendDom, () => {
    //           try {
    //             new TrackEvent().setEvent('recommend_article_text', 'read', id, count, '')
    //           } catch (error) {
    //             console.log(error)
    //           }
    //         })
    //       }
    //     })
    //     try {
    //       // 推荐文章的加载次数
    //       const count = response.data.length
    //       new TrackEvent().setEvent('recommen_article_record', 'recommend', id, count, null)
    //     } catch (error) {
    //       console.log(error)
    //     }
    //   }
    // })
  },
  updated() {
    this.$nextTick(function () {
      this.reloadInstagram();
    });
  },
  beforeDestroy() {
    // 删除票券阅读激活标识
    const couponRead = window.sessionStorage.getItem('coupon_read');
    if (couponRead) window.sessionStorage.removeItem('coupon_read');

    // window.removeEventListener('scroll', this.scrollHandler)
  },
};
</script>

<style lang="scss">
@media screen and (min-width: 1200px) {
  #article-container {
    width: 1200px;
    margin: 0 auto;
    .articles-column {
      max-width: 830px;
    }
  }
}

#first-wrapper {
  min-height: 900px;
}

#right-sidebar {
  width: 300px;
  margin-left: 66px;
  margin-top: 60px;
  background-color: rgb(220, 220, 220) !important;
}

hr.divider {
  background-color: #ffa0b9;
  width: 80%;
  margin: 80px auto;
}

.ads {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

.instagram-media {
  margin: 0 auto 12px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.p-denied {
  text-align: center;
  margin: 20px;
  font-size: 1.5rem;
}

.answer-icon {
  width: 56px;
  height: 56px;
  position: fixed;
  bottom: 166px;
  right: 15px;
  cursor: pointer;
}
</style>
