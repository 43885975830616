var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"infiniteWrapper"},[(_vm.isChannel && !_vm.isLoading)?[_c('div',{staticClass:"channel-title"},[_c('h1',[_c('span',{staticClass:"underline"},[_vm._v(_vm._s(_vm.current_channel.title))])]),_vm._v(" "),_c('div',{staticClass:"article-categories"},_vm._l((_vm.categories),function(cate,index){return _c('a',{key:cate.key,on:{"click":function($event){$event.preventDefault();return _vm.jumpByBrowser({ name: _vm.routeName, query: { category: cate.key } })}}},[_c('span',{staticClass:"category has-text-black",class:_vm.cateClass(index + 1)},[_vm._v(_vm._s(cate.name))])])}),0)])]:(_vm.routeName === 'tag' && _vm.tagData)?[_c('div',{staticClass:"channel-title tag-title"},[_c('h1',[_c('span',{staticClass:"underline"},[_vm._v(_vm._s(_vm.tagData.name))])]),_vm._v(" "),_c('p',{staticClass:"tag-description"},[_vm._v("\n        "+_vm._s(_vm.tagData.description)+"\n      ")])])]:_vm._e(),_vm._v(" "),_c('div',{attrs:{"id":"article-list"}},[_vm._l((_vm.articles),function(article,i){return [_c('div',{key:article.id,staticClass:"list-article"},[(_vm.isMobile)?_c('p',{staticClass:"author"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.jumpByBrowser({ path: article.author_url })}}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.authorAvatar(article.author_avatar)}}),_vm._v(" "),_c('span',{staticClass:"name has-text-black"},[_vm._v(_vm._s(article.author_name))])]),_vm._v(" "),_c('span',{staticClass:"date"},[_vm._v(_vm._s(article.published_at))])]):_vm._e(),_vm._v(" "),_c('a',{staticClass:"cover img-container",on:{"click":function($event){$event.preventDefault();return _vm.jumpByBrowser({
            path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
            params: {
              title: article.title
            }
          })}}},[_c('img',{staticClass:"big-content-img",attrs:{"src":article.image_url,"alt":article.title}})]),_vm._v(" "),_c('div',{staticClass:"a-info"},[(!_vm.isMobile)?_c('div',[_c('p',{staticClass:"author"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.jumpByBrowser({ path: article.author_url })}}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.authorAvatar(article.author_avatar)}}),_vm._v(" "),_c('span',{staticClass:"name has-text-black"},[_vm._v(_vm._s(article.author_name))])]),_vm._v(" "),_c('span',{staticClass:"date"},[_vm._v(_vm._s(article.published_at))])]),_vm._v(" "),_c('h2',{staticClass:"title has-text-black"},[_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.jumpByBrowser({ name: _vm.routeName, query: { category: article.category.path } })}}},[_c('span',{staticClass:"category has-text-black",class:_vm.cateClass(article.category.id)},[_vm._v(_vm._s(article.category.title))])]),_vm._v(" "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.jumpByBrowser({
                  path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
                  params: {
                    title: article.title
                  }
              })}}},[_vm._v("\n                "+_vm._s(article.title)+"\n              ")])]),_vm._v(" "),_c('p',{staticClass:"abstract"},[_vm._v("\n              "+_vm._s(article.abstract)+"\n            ")])]):_vm._e(),_vm._v(" "),_c('p',{class:_vm.isMobile ? 'has-text-centered tags' : 'tags'},_vm._l((article.tags.slice(0, 3)),function(tag){return _c('a',{key:tag,staticClass:"tag",on:{"click":function($event){$event.preventDefault();_vm.jumpByBrowser({ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } })}}},[_vm._v("#"+_vm._s(tag))])}),0)]),_vm._v(" "),(_vm.isMobile)?_c('h2',{staticClass:"title has-text-black"},[_c('span',{staticClass:"category has-text-black",class:_vm.cateClass(article.category.id)},[_vm._v(_vm._s(article.category.title))]),_vm._v(" "),_c('a',{on:{"click":function($event){$event.preventDefault();return _vm.jumpByBrowser({
              path: ("/" + (article.parent_path) + "/" + (article.child_path) + "/" + (article.id)),
              params: {
                title: article.title
              }
            })}}},[_vm._v("\n            "+_vm._s(article.title)+"\n          ")])]):_vm._e()])]})],2),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoading),expression:"isLoading"}]})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }