<template>
  <div class="breadcrumb">
    <ul>
      <li v-for="(item, idx) in breadcrumbList" :key="idx">
        <a @click.prevent="jumpByBrowser({ name: item.name, params: { channel: item.parent, sub_channel: item.child, category: item.category, title: item.title } })">
          {{ item.title }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ['breadcrumbList'],
  name: 'BreadCrumb',
  data() {
    return {
    };
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link) this.$router.push(this.breadcrumbList[pRouteTo].link);
    },
  },
};
</script>

<style lang="scss">
@media screen and (min-width: 810px) {
  .breadcrumb {
    ul {
      margin: 20px 0 !important;
    }
  }
}

.breadcrumb {
  margin-bottom: line(3);
  ul {
    margin: 20px 15px;
    display: flex;
    list-style: none;
    padding: 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 1px;
    text-align: center;
  }
  li {
    margin-left: unset;
  }
  li a {
    cursor: pointer;
    color: #404040;
  }
  li + li::before {
    margin: 0 5px;
    font-weight: 400;
    color: #404040 !important;
    position: relative;
    content: '/';
    top: unset;
    left: unset;
    transform: none;
    width: unset;
    height: unset;
  }
  li:last-child a {
    font-weight: 700;
    color: #ffa0b9;
  }
}
</style>
