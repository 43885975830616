<template>
  <section id="home-slide">
    <div v-if="isLoading">
      <img class="default-image" :src="require('@/../images/default.jpg')" />
    </div>
    <div v-else :class="desktopSlickClass">
      <slick ref="homeSliderSlick" :options="slickOptions" @afterChange="handleAfterChange">
        <div
          class="content has-text-centered slide-content"
          v-for="(slide, i) in home_slides"
          :data-show="slide.ga_show"
          :data-click="slide.ga_click"
          :data-title="slide.title"
          @click="clickSlide(slide)"
          :key="i"
          ref="slickRef"
        >
          <router-link
            v-if="slide.type == 'article'"
            :to="{
              name: 'article',
              params: {
                channel: slide.parent_path,
                sub_channel: slide.child_path,
                id: slide.id,
                title: slide.title
              }
            }"
            @click.native="jumpArticle(slide.id, i)"
          >
            <div class="slide-bg">
              <img
                class="slide-image"
                width="100vw"
                height="62.5vw"
                :srcset="`${slide.image_s_url} 800w, ${slide.image_url} 1024w`"
                :src="slide.image_s_url"
              />
            </div>
          </router-link>
          <a v-else :href="slide.link" target="_blank">
            <div class="slide-bg">
              <img
                class="slide-image"
                width="100vw"
                height="62.5vw"
                :srcset="`${slide.image_s_url} 800w, ${slide.image_url} 1024w`"
                :src="slide.image_s_url"
              />
            </div>
          </a>
          <div class="slide-text">
            <p v-if="slide.tags">
              <router-link
                v-for="tag in slide.tags.slice(0, 3)"
                :key="tag"
                class="tag"
                :to="{
                  name: 'tag',
                  params: { slug: tag.toLowerCase(), title: tag }
                }"
                >#{{ tag }}</router-link
              >
            </p>
            <router-link
              v-if="slide.type == 'article'"
              :to="{
                name: 'article',
                params: {
                  channel: slide.parent_path,
                  sub_channel: slide.child_path,
                  id: slide.id,
                  title: slide.title
                }
              }"
              @click.native="jumpArticle(slide.id, i)"
            >
              <h3 class="title">
                <span>{{ slide.title }}</span>
              </h3>
            </router-link>
            <a v-else :href="slide.link" target="_blank">
              <h3 class="title">
                <span>{{ slide.title }}</span>
              </h3>
            </a>
          </div>
        </div>
      </slick>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { fullTitle } from 'src/share';
import { throttle } from 'throttle-debounce';
import useObserver from '../../../tools/useObserver';

export default {
  name: 'home_slide',
  props: ['isLoading', 'home_slides', 'type'],
  data() {
    return {
      slickOptions: {
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: 1,
        dots: true,
        // arrows: false,
        swipeToSlide: true,
        centerMode: false,
        centerPadding: '0px',
        timeId: null,
      },
    };
  },
  computed: {
    ...mapState(['isMobile']),
    desktopSlickClass() {
      if (!this.isMobile && !!this.home_slides && this.home_slides.length < 3) {
        return 'single-slider-wrapper';
      }
      return '';
    },
  },
  watch: {
    isMobile() {
      this.resetSlider();
    },
    home_slides() {
      this.resetSlider();
    },
  },
  methods: {
    resetSlider() {
      if (!this.isMobile && !!this.home_slides && this.home_slides.length >= 3) {
        this.slickOptions.centerMode = true;
        this.slickOptions.centerPadding = `${(document.body.clientWidth - 928) / 2}px`;
      } else {
        this.slickOptions.centerMode = false;
        this.slickOptions.centerPadding = '0px';
      }

      if (this.$refs.homeSliderSlick !== undefined) {
        this.$refs.homeSliderSlick.reSlick();
      }
    },
    handleAfterChange(event, slick, currentSlide) {
      const ga_show = slick.$slides[currentSlide].childNodes[0].childNodes[0].dataset.show;
      const title = fullTitle(slick.$slides[currentSlide].childNodes[0].childNodes[0].dataset.title);
      this.$gtag.pageview({
        page_path: ga_show,
        page_title: title,
        page_location: window.location.href,
        send_to: process.env.GA_TRACKING_ID,
      });
      // 添加统计代码
      // 引入js函数方法
      try {
        new TrackEvent().setEvent('home_slide', 'handleAfterChange', ga_show, null, null);
      } catch (e) {}
    },
    clickSlide(slide) {
      this.$store.dispatch('click', { media: 'home_slide', id: slide.slide_id });
      this.$gtag.pageview({
        page_path: slide.ga_click,
        page_title: fullTitle(slide.title),
        send_to: process.env.GA_TRACKING_ID,
      });
      // 添加统计代码
      // 引入js函数方法
      try {
        new TrackEvent().setEvent('home_slide', 'clickSlide', '', null, null);
      } catch (e) {}
    },
    resizeHandler: throttle(800, function () {
      this.resetSlider();
    }),
    jumpArticle(id, index) {
      if (this.type === 'home') {
        // 首页轮播图 点击文章
        try {
          new TrackEvent().setEvent('click_home_article', 'click', id, index + 1, null);
        } catch (e) {}
      }
    },
  },
  mounted() {
    this.resetSlider();
    window.addEventListener('resize', this.resizeHandler);

    if (this.type === 'home') {
      this.$nextTick(() => {
        this.$store.dispatch('loadHome').then(() => {
          // 首页轮播图 版位曝光
          this.timeId = setTimeout(() => {
            try {
              new TrackEvent().setEvent('exposure_home_slide', 'read', this.home_slides.length, null, null);
            } catch (e) {}
          }, 500);
          // 首页轮播图 文章曝光
          this.$refs.slickRef.forEach((el, i) => {
            useObserver(el, () => {
              try {
                new TrackEvent().setEvent('read_home_article', 'click', this.home_slides[i].id, i + 1, null);
              } catch (e) {}
            });
          });
        });
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler);
    clearTimeout(this.timeId);
  },
};
</script>
<style lang="scss">
@media screen and (max-width: 600px) {
  #home-slide {
    min-height: 100vw;
    .slick-slider {
      .slick-arrow {
        top: 30%;
        z-index: 4;
        &.slick-prev {
          left: 15px;
        }
        &.slick-next {
          right: 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  #home-slide .single-slider-wrapper {
    max-width: 928px;
    margin: auto;
    .slick-slider .slick-slide .content .slide-bg {
      background-color: #fff;
      .slide-image {
        opacity: 1;
      }
    }
  }
  #home-slide {
    .slick-slider {
      .slick-arrow {
        top: 40%;
        z-index: 4;
        width: 30px;
        height: 30px;
        &::before {
          font-size: 30px;
        }
        &.slick-prev {
          left: 25px;
        }
        &.slick-next {
          right: 25px;
        }
      }
    }
  }
  #home-slide .slick-slider .slick-slide {
    max-width: 928px;
    .content {
      .slide-bg {
        background-color: #ffdbcf;
        .slide-image {
          max-width: 928px;
          max-height: 580px;
          opacity: 0.6;
        }
      }
      .slide-text {
        h3.title {
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 45px;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  #home-slide .slick-slider .slick-slide {
    max-width: 1200px;
    .content {
      .slide-image {
        max-width: 1200px;
        max-height: 750px;
      }
      .slide-text {
        h3.title {
          font-weight: bolder;
          font-size: 16px;
          line-height: 177%;
        }
      }
    }
  }
}

#home-slide {
  img.default-image {
    width: 100vw;
    height: 62.5vw;
    object-fit: cover;
    max-width: 1200px;
    max-height: 750px;
    display: block;
    margin: auto;
  }
  .slick-slider {
    box-sizing: border-box;
    margin: 0 auto;
    clear: both;
    width: 100%;

    .slick-slide.slick-active .slide-content {
      .slide-image {
        background: #ffffff !important;
        opacity: 1 !important;
      }
      .slide-text {
        visibility: visible !important;
      }
    }

    .slick-slide {
      height: auto;
      width: 100%;
      .content {
        .slide-image {
          width: 100vw;
          height: 62.5vw;
          display: block;
          object-fit: cover;
        }
        .slide-text {
          visibility: hidden;
          height: 160px;
          p {
            padding: 5px;
            margin: 0;
          }
          h3.title {
            letter-spacing: 1px;
            padding: 5px 15px;
            margin: 0;
            span {
              background-color: #ffdbcf;
              padding: 1px 6px;
            }
          }
          .tag {
            font-size: 13px;
            line-height: 185%;
            text-align: center;
            letter-spacing: 1px;
            color: #ffa0b9;
            background-color: transparent;
            margin: 10px 6px 5px;
            border: 1px solid #ffa0b9;
            border-radius: 50px;
          }
        }
      }
    }
    .slick-dots {
      bottom: 176px;
      height: 35px;
      li {
        button {
          border-radius: 50%;
          border: 1px solid #ffffff;
          width: 6px;
          height: 6px;
          padding: 3px;
        }
        button:before {
          content: '';
        }
      }
      li.slick-active button {
        background-color: #ffffff;
      }
    }
  }
}
</style>
