<template>
  <section id="whats-new">
    <div class="home-block-title has-text-centered has-text-black">
      <h2 ref="titleRef">
        <span>W</span>
        hat's New
        <span class="has-text-lookin-darker">最新文章</span>
      </h2>
    </div>
    <div class="slick-container">
      <b-icon icon="angle-left" class="slick-arrow prev" @click.native="prev()"></b-icon>
      <slick ref="whatsNewSlick" :options="slickOptions">
        <div
          class="content has-text-centered"
          ref="articleRef"
          v-for="(article, index) in new_articles"
          :key="article.id"
        >
          <a
            @click.prevent="handleArticle(article, index)"
          >
            <!-- <img :src="article.image_url || require('@/../images/default.jpg')" class="content-img" /> -->
            <LazyImage class="content-img" :src="article.image_url" />
          </a>
          <div class="slide-text">
            <p>
              <a
                v-for="tag in article.tags.slice(0, 3)"
                :key="tag"
                class="tag"
                @click.prevent="jumpByBrowser({ name: 'tag', params: { slug: tag.toLowerCase(), title: tag } })"
              >
                #{{ tag }}
              </a>
            </p>
            <p class="has-text-lookin-darker">
              <a @click.prevent="jumpByBrowser({ name: 'new', query: { category: article.category.path } })">
                <span class="category has-text-black" :class="cateClass(article.category.id)">
                  {{ article.category.title }}
                </span>
              </a>

              <span class="date-author"> {{ article.published_at }} ‧ {{ article.author_name }}</span>
            </p>
            <a
              @click.prevent="handleArticle(article, index)"
            >
              <h3 class="title">{{ article.title }}</h3>
            </a>
          </div>
        </div>
      </slick>
      <b-icon icon="angle-right" class="slick-arrow next" @click.native="next()"></b-icon>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { categoryClass } from 'src/share';
import LazyImage from '../../components/common/LazyImage.vue';
import useObserver from '../../../tools/useObserver';

export default {
  props: {
    new_articles: {
      type: Array,
      default: () => [],
    },
  },
  components: { LazyImage },
  data() {
    return {
      slickOptions: {
        // autoplay: true,
        infinite: true,
        slidesToShow: null,
        arrows: false,
        swipeToSlide: true,
      },
    };
  },
  watch: {
    isMobile() {
      this.slickOptions.slidesToShow = this.isMobile ? 1 : 2;
      if (this.$refs.whatsNewSlick !== undefined) {
        this.$refs.whatsNewSlick.reSlick();
      }
    },
  },
  methods: {
    prev() {
      this.$refs.whatsNewSlick.prev();
    },
    next() {
      this.$refs.whatsNewSlick.next();
    },
    cateClass: (id) => categoryClass(id),
    handleArticle(article, index) {
      try {
        new TrackEvent().setEvent('click_home_news_article', 'click', article.id, index + 1, '');
      } catch (e) {}
      this.jumpByBrowser({
        path: `/${article.parent_path}/${article.child_path}/${article.id}`,
        params: {
          title: article.title,
        },
      });
    },
  },
  computed: {
    ...mapState(['isMobile']),
  },
  created() {
    this.slickOptions.slidesToShow = this.isMobile ? 1 : 2;
  },
  mounted() {
    // 版位曝光
    useObserver(this.$refs.titleRef, () => {
      try {
        new TrackEvent().setEvent('exposure_home_news', 'read', this.new_articles.length, null, '');
      } catch (error) {}
    });
    // 文章曝光事件
    const { articleRef } = this.$refs;
    if (articleRef) {
      articleRef.forEach((el, index) => {
        useObserver(el, () => {
          try {
            new TrackEvent().setEvent('read_home_news_article', 'read', this.new_articles[index].id, index + 1, '');
          } catch (error) {}
        });
      });
    }
  },
};
</script>
<style lang="scss">
#whats-new {
  position: relative;
  h2 {
    span:nth-child(1) {
      width: 55px;
    }
    span.has-text-lookin-darker {
      left: 116px;
    }
  }
  .slick-container {
    position: relative;
    .slick-slider {
      // margin: 0 auto;
      .slick-slide {
        .content {
          padding: 3px 0 100px;
          img {
            top: 0;
            height: auto;
            width: 80%;
            // max-width: 450px;
            // max-height: 280px;
            width: 450px;
            height: 237px;
            margin: 0 auto;
            object-fit: cover;
          }
          .slide-text {
            max-width: 450px;
            margin: 0 auto;
            p {
              padding: 5px;
              margin: 0;
              span.category {
                font-size: 13px;
                line-height: 185%;
                letter-spacing: 1px;
                padding: 1px 6px;
              }
              span.date-author {
                font-family: 'Ubuntu Condensed', '微軟正黑體', 'Microsoft JhengHei', 'LiHei Pro', Arial, sans-serif;
                font-weight: normal;
                font-size: 14px;
                line-height: 71%;
                letter-spacing: 1px;
              }
            }
            h3.title {
              font-size: 16px;
              line-height: 140%;
              letter-spacing: 1px;
              padding: 5px 15px;
              margin: 0;
            }
            .tag {
              font-size: 13px;
              line-height: 185%;
              text-align: center;
              letter-spacing: 1px;
              color: #ffa0b9;
              background-color: transparent;
              margin: 6px 6px 0;
              border: 1px solid #ffa0b9;
              border-radius: 50px;
            }
          }
        }
      }
      .slick-dots {
        top: 200px;
        height: 35px;
        li {
          button {
            border-radius: 50%;
            border: 1px solid #ffffff;
            width: 6px;
            height: 6px;
            padding: 3px;
          }
          button:before {
            content: '';
          }
        }
        li.slick-active button {
          background-color: #ffffff;
        }
      }
    }
    .slick-arrow {
      color: #ffa0b9;
      font-size: 24px;
      position: absolute;
      top: 35%;
      z-index: 3;
      cursor: pointer;
    }
    .slick-arrow.prev {
      left: 8px;
    }
    .slick-arrow.next {
      right: 8px;
    }
  }
  @media screen and (min-width: 1200px) {
    h2 {
      z-index: 1;
      &::after {
        content: '';
        height: 8px;
        background: #ffa0b9;
        position: absolute;
        left: 6px;
        right: 7px;
        bottom: 8px;
        z-index: -1;
      }
    }
    .slick-container {
      .slick-slider {
        // max-width: 1410px;
        .slick-slide .content {
          padding: 15px 0 100px;
          img {
            margin: 0 auto 1rem;
          }
        }
      }
    }
  }
}
</style>
