<template>
  <img ref="imgRef" :src="imgSrc" alt="" :style="{ 'object-fit': isCover ? 'cover' : '' }" />
</template>

<script>
import loadingImg from '../../../images/loading/coolsis.png';
import errorImg from '../../../images/default.jpg';
import useObserver from '../../../tools/useObserver';

export default {
  name: 'CommonImage',
  props: {
    src: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imgSrc: loadingImg,
      isCover: true,
    };
  },
  mounted() {
    // 图片懒加载
    useObserver(this.$refs.imgRef, () => {
      // 进入可视区，加载图片
      this.loadingImage();
    });
  },
  methods: {
    loadingImage() {
      const img = new Image();
      img.src = this.src || '';
      img.onload = () => {
        this.isCover = false;
        this.imgSrc = this.src;
      };
      img.onerror = () => {
        this.imgSrc = errorImg;
      };
    },
  },
};
</script>
