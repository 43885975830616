function isRouterObj(params) {
  if (!params) return false;
  // 检查是否为对象
  if (typeof params !== 'object') return false;
  // 检查是否包含路由相关的属性
  return (params.name || params.path);
}
export default {
  methods: {
    jumpByBrowser(data, target = '_self') {
      let url = '';
      if (isRouterObj(data)) url = this.$router.resolve(data).href;
      else url = data;
      // window.location.href = url;
      // if (url) window.open(url, target);
      if (url) window.open(url, '_blank');
    },
  },
};
