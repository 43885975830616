<template>
  <section id='my-subscriptions'>
    <h1>訂閱紀錄</h1>
    <div class="subscriptions" v-if="!isLoading">
      <div v-for="(author, i) in subscriptions" :key="author.id" class="subscription">
        <div class="author">
          <img class='avatar' :src="authorAvatar(author.avatar)">
          <span class="name">{{ author.name }}</span>
          <span :class="followClass(author.unfollow)" @click="follow(author)">
            Follow
            <img :src="followIcon(author.unfollow)">
          </span>
        </div>
        <ul class="articles">
          <li v-for="(article, i) in author.articles" :key="article.id">
            <router-link :to="article.link" class="title-wrapper">
              <h3 class="has-text-black">{{ article.title }}</h3>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState(['subscriptions']),
    authorAvatar: function () {
      return avatar => (!!avatar) ? avatar : require('@/../images/editor.jpg')
    },
    followIcon: function () {
      return unfollow => (unfollow == true) ? require('@/../images/unfollow.svg') : require('@/../images/follow.svg')
    },
    followClass: function () {
      return unfollow => (unfollow == true) ? 'unfollow' : 'follow'
    }
  },
  methods: {
    follow(author) {
      if (author.unfollow == true) {
        this.$store.dispatch('follow', { author_id: author.id }).then(() => {
        }).catch((error) => {
          console.log(error.data)
        })
      } else {
        this.$store.dispatch('unfollow', { author_id: author.id }).then(() => {
        }).catch((error) => {
          console.log(error.data)
        })
      }
    }
  },
  created() {
    this.$store.dispatch('loadSubscriptions').then(() => {
      this.isLoading = false
    })
  },
};
</script>
<style lang="scss">
#my-subscriptions {
  margin: 10px auto;
  h1 {
    margin: 20px 15px 30px;
    font-weight: bold;
    font-size: 18px;
    line-height: 15px;
    letter-spacing: 2px;
  }
  .subscriptions {
    margin-bottom: 100px;
    .subscription {
      padding: 20px 24px;
      .author {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-family: Ubuntu Condensed;
        font-style: normal;
        font-weight: normal;
        .avatar {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          margin-right: 10px;
        }
        .name {
          font-size: 18px;
          line-height: 10px;
          letter-spacing: 1px;
          margin-right: 18px;
        }
        .follow {
          border: #F08F7A solid 1px;
          border-radius: 24px;
          padding: 5px 13px;
          font-size: 15px;
          line-height: 10px;
          letter-spacing: 1px;
          color: #F08F7A;
          cursor: pointer;
          img {
            width: 12px;
          }
        }
        .unfollow {
          border: #989595 solid 1px;
          border-radius: 24px;
          padding: 5px 13px;
          font-size: 15px;
          line-height: 10px;
          letter-spacing: 1px;
          color: #989595;
          cursor: pointer;
          img {
            width: 12px;
          }
        }
      }
      .title-wrapper {
        width: 80vw;
        h3 {
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 19px;
          text-decoration-line: underline;
          display: -webkit-box;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: normal;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .articles {
        li {
          padding: 7px;
          margin-left: 18px;
          border-left: #FFC2AE solid 4px;
        }
        li:nth-child(odd) {
          border-left: #FFDBCF solid 4px;
        }
      }
    }
    .subscription:nth-child(odd) {
      background: rgba(255,240,238, 0.7);
    }
  }
}

@media screen and (min-width: 1200px) {
  #my-subscriptions {
    max-width: 1000px;
    width: 1000px;
    h1 {
      display: inline-block;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: 2px;
      padding-top: 24px;
      border-bottom: 4px solid #FFC2AE;
    }
  }
}
</style>
