<template>
  <section>
    <b-sidebar :type="'is-nav-bg'" :fullheight="true" :fullwidth="true" :right="true" v-model="open">
      <div id="search-drawer" class="has-background-nav-bg drawer" @click="handleSearchDrawer">
        <img
          :src="require('@/../images/icon-close.svg')"
          alt="Close"
          class="tail-icon close"
          @click="emitCloseDrawer"
        />
        <h2 class="title search-title eng-font">Search</h2>
        <Associate style="width: 100%" :list="associateList" @click="handleAssociate">
          <div style="position: relative; margin-bottom: 9px">
            <input
              type="search"
              placeholder="Search..."
              class="search-input"
              v-model="keyword"
              inputmode="search"
              @keydown.enter="submitEvent"
              @keydown.esc="emitCloseDrawer"
              @input="onInput"
            />
            <img @click="submitEvent" :src="require('@/../images/icon-search.svg')" alt="Search" class="tail-icon" />
          </div>
        </Associate>
        <div class="search-history" ref="historyRef" v-if="historyList && historyList.length > 0">
          <TagItem v-for="(tag, index) in historyList" :key="index">
            <span @click="handleTag(tag)">{{ tag }}</span>
          </TagItem>
          <button class="more" @click.stop="isHistoryMore = true">
            <i></i>
            <i></i>
            <i></i>
          </button>
          <HistoryMore
            v-if="isHistoryMore"
            :list="historyTag"
            @click="handleTag"
            @del="handleHistoryTagDel"
            @delAll="delHistoryAll"
          />
        </div>
        <Tag title="Hot Tag" style="margin-top: 40px; margin-bottom: 15px" v-if="search_tags && search_tags.length > 0">
          <TagItem v-for="(word, index) in search_tags" :key="index">
            <span @click="handleTag(word)">#{{ word }}</span>
          </TagItem>
        </Tag>
      </div>
    </b-sidebar>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Tag from '../search/Tag.vue';
import TagItem from '../search/TagItem.vue';
import Associate from '../search/Associate.vue';
import HistoryMore from '../search/HistoryMore.vue';
import { getAssociateList } from '../../../api/search';
import { useReplace } from '../../../tools/useReplace';

export default {
  props: ['open'],
  components: {
    Tag, TagItem, Associate, HistoryMore,
  },
  data() {
    return {
      keyword: '',
      showDel: false,
      associateList: [],
      timeId: null,
      historyTag: [],
      historyList: [],
      historyParams: {
        idx: 5,
        count: 0,
      },
      isHistoryMore: false,
    };
  },
  computed: {
    ...mapState(['search_tags']),
  },
  watch: {
    open(val) {
      if (val) {
        this.$nextTick(() => {
          if (this.$refs.historyRef) {
            const list = this.$refs.historyRef.querySelectorAll('button');
            list.forEach((el, index) => {
              if (el.offsetLeft === 5) {
                this.historyParams.count++;
                if (this.historyParams.count === 2) {
                  // 超过一行截取
                  this.historyParams.idx = index;
                }
              }
            });
            // console.log(this.historyParams)
            this.historyList = this.historyTag.slice(0, this.historyParams.idx);
          }
        });
      } else {
        this.historyParams.idx = 5;
        this.historyParams.count = 0;
      }
    },
  },
  methods: {
    emitCloseDrawer() {
      this.$emit('closeDrawer', true);
    },
    handleSearchDrawer() {
      this.isHistoryMore = false;
    },
    submitEvent() {
      if (this.keyword) {
        // this.$message(`seraching... ${this.keyword}`)
        // let keywords = this.keyword.match(/\S+/g).join('-')
        // 去除空格
        const value = this.keyword.split(/[\t\r\f\n\s]*/g).join('');
        // 设置历史记录
        this.storageHistoryFn(value);
        window.location.href = `/nuxt/search?keyword=${value}`;
      }
    },
    handleTag(value) {
      this.$emit('closeDrawer', true);
      const tag = value.replace(/<\/?[^>]*>/g, '');
      window.location.href = `/nuxt/search?keyword=${tag}`;
    },
    handleAssociate(value) {
      this.$emit('closeDrawer', true);
      const tag = value.replace(/<\/?[^>]*>/g, '');
      this.storageHistoryFn(tag);
      window.location.href = `/nuxt/search?keyword=${tag}`;
    },
    onInput(event) {
      if (this.timeId) {
        // 如果存在定时器就清空
        clearTimeout(this.timeId);
      }
      // 加个防抖
      this.timeId = setTimeout(async () => {
        const { value } = event.target;
        if (value.trim() !== '') {
          this.showDel = true;

          // 获取搜索联想列表
          try {
            const { code, result } = await getAssociateList(value);
            if (code === 200) {
              // this.associateList = result
              // 高亮
              this.associateList = result.map((item) => useReplace(item, new RegExp(value, 'i'), (val) => `<font color="#f08f7a">${val}</font>`));
            }
          } catch (error) {
            console.log(error);
          }
        } else {
          this.showDel = false;
          this.associateList = [];
        }
      }, 500);
    },
    handleDel() {
      this.keyword = '';
      this.showDel = false;
      this.associateList = [];
    },
    handleHistoryTagDel(tag) {
      this.historyTag = this.historyTag.filter((item) => item !== tag);
      this.historyList = this.historyTag;
      window.localStorage.setItem('history-tag', JSON.stringify(this.historyTag));
    },
    delHistoryAll() {
      this.historyList = [];
      this.historyTag = [];
      window.localStorage.setItem('history-tag', '[]');
    },
    storageHistoryFn(value) {
      const leg = this.historyTag.length;
      // 限制最多显示15个
      if (leg === 15) {
        // 去掉数组最后一个值
        this.historyTag.pop();
        this.historyTag.unshift(value);
        window.localStorage.setItem('history-tag', JSON.stringify(this.historyTag));
      } else {
        // this.historyTag.unshift(tag)
        let arr = [];
        arr = this.historyTag.filter((item) => item !== value);
        arr.unshift(value);
        this.historyTag = arr;
        window.localStorage.setItem('history-tag', JSON.stringify(arr));
      }
    },
  },
  created() {
    const historyTag = window.localStorage.getItem('history-tag');
    this.historyTag = JSON.parse(historyTag || '[]');
    this.historyList = this.historyTag;
  },
  mounted() {},
};
</script>
<style lang="scss">
.close {
  position: absolute;
  right: 4.8%;
  top: 2.46%;
}

.drawer {
  h2.title,
  h2.title span {
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
  }
}

#search-drawer {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  padding: 0 30px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(220, 220, 220, 1) !important;

  .search-title {
    margin-top: 33%;
  }

  .tags {
    // margin: 0 10%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .search-history {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .more {
      height: 26px;
      color: black;
      font-size: 13px;
      background-color: transparent;
      margin: 5px;
      padding: 0 5px;
      border: black solid 1px;
      border-radius: 24px;
      display: flex;
      align-items: center;
      cursor: pointer;
      i {
        width: 4px;
        height: 4px;
        margin-right: 4px;
        background-color: black;
        border-radius: 999px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

.search-input {
  // width: 280px;
  width: 100%;
  height: 40px;
  border-radius: 20px;
  border: white;
  padding: 0 40px 0 20px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  &:focus {
    box-shadow: 0px 3px 6px rgba(240, 143, 122, 0.6);
  }
  &::placeholder {
    color: #989595;
  }
  & + .tail-icon {
    position: absolute;
    right: 12px;
    top: 6px;
  }
}

.tag-link {
  color: #ffa0b9;
  font-size: 13px;
  padding: 0 10px;
  margin: 5px 6px;
  line-height: 24px;
  border: #ffa0b9 solid 1px;
  border-radius: 24px;
  letter-spacing: 1px;
  position: relative;
  text-decoration: none;
  outline: 0;
  & + .tag-link {
    margin-left: 5px;
  }
}
</style>
